/* eslint-disable */
import client from '@/api/client';

async function fetchStructuresPublics() {
    try {
        const response = await client.get(`/api/structure-publics`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch publics details:', error);
        return error;
    }
};

async function fetchDegreesPublics() {
    try {
        const response = await client.get(`/api/degrees`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch degrees details:', error);
        return error;
    }
};

async function fetchOrganizationsPublics() {
    try {
        const response = await client.get(`/api/organizations`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch organizations details:', error);
        return error;
    }
};

async function fetchLegalFormsPublics() {
    try {
        const response = await client.get(`/api/legal-forms`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch legal forms details:', error);
        return error;
    }
};

async function fetchTypesPublics() {
    try {
        const response = await client.get(`/api/structure-types`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch types details:', error);
        return error;
    }
};

async function fetchStructures(page = 1) {
    try {
        const response = await client.get('/api/structures', {
            params: { page, per_page: pageSize },
        });
        return response.data.data;
    } catch (error) {
        console.error('Failed to fetch structures :', error);
    }
};

async function fetchTrainingActions(page = 1, pageSize = 5) {
    try {
        const response = await client.get('/api/training-actions', {
            params: { page, per_page: pageSize },
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch training actions:', error);
    }
};

async function fetchStructuresPrivees() {
};

const fetchServices = {
    fetchStructuresPublics,
    fetchStructuresPrivees,
    fetchDegreesPublics,
    fetchOrganizationsPublics,
    fetchLegalFormsPublics,
    fetchTypesPublics,
    fetchStructures,
    fetchTrainingActions,
};

export default fetchServices;
