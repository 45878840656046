/* eslint-disable */
import client from '@/api/client';

// Fetch all wishlists
async function fetchWishlists() {
    try {
        const response = await client.get('/api/wishlists');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch wishlists:', error);
        throw error;
    }
}

// Fetch wishlist by ID
async function fetchWishlistById(wishlistId) {
    try {
        const response = await client.get(`/api/wishlists/${wishlistId}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch wishlist with ID ${wishlistId}:`, error);
        throw error;
    }
}

// Create a new wishlist
async function createWishlist(wishlistData) {
    try {
        const response = await client.post('/api/wishlists', wishlistData);
        return response.data;
    } catch (error) {
        console.error('Failed to create wishlist:', error);
        throw error;
    }
}

// Update an existing wishlist
async function updateWishlist(wishlistId, wishlistData) {
    try {
        const response = await client.put(`/api/wishlists/${wishlistId}`, wishlistData);
        return response.data;
    } catch (error) {
        console.error(`Failed to update wishlist with ID ${wishlistId}:`, error);
        throw error;
    }
}

// Delete a wishlist
async function deleteWishlist(wishlistId) {
    try {
        const response = await client.delete(`/api/wishlists/${wishlistId}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to delete wishlist with ID ${wishlistId}:`, error);
        throw error;
    }
}

// Fetch wishlists by user and event
async function fetchWishlistsByUserAndEvent(userId, eventId, preEvent) {
    try {
        const response = await client.get(`/api/wishlists/user/${userId}/event/${eventId}?is_pre_event=${preEvent}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch wishlists for user ${userId} and event ${eventId}:`, error);
        throw error;
    }
}

// Fetch wishlists by event
async function fetchWishlistsByEvent(eventId) {
    try {
        const response = await client.get(`/api/wishlists/event/${eventId}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch wishlists for event ${eventId}:`, error);
        throw error;
    }
}

// Fetch wishlists by user
async function fetchWishlistsByUser(userId) {
    try {
        const response = await client.get(`/api/wishlists/user/${userId}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch wishlists for user ${userId}:`, error);
        throw error;
    }
}

// Fetch wishlists by structure
async function fetchWishlistsByStructure(structureId) {
    try {
        const response = await client.get(`/api/wishlists/structure/${structureId}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch wishlists for structure ${structureId}:`, error);
        throw error;
    }
}

// Fetch wishlists by structure and event
async function fetchWishlistsByStructureAndEvent(structureId, eventId) {
    try {
        const response = await client.get(`/api/wishlists/structure/${structureId}/event/${eventId}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch wishlists for structure ${structureId} and event ${eventId}:`, error);
        throw error;
    }
}

// Export all wishlist services
const WishlistServices = {
    fetchWishlists,
    fetchWishlistById,
    createWishlist,
    updateWishlist,
    deleteWishlist,
    fetchWishlistsByUserAndEvent,
    fetchWishlistsByEvent,
    fetchWishlistsByUser,
    fetchWishlistsByStructure,
    fetchWishlistsByStructureAndEvent
};

export default WishlistServices;
