<template>
    <div :class="bgColor" class="rounded-md p-4 mt-4">
        <div class="flex items-center">
            <div class="flex-shrink-0">
                <component :is="icon" class="h-5 w-5" :class="iconColor" aria-hidden="true" />
            </div>
            <div class="ml-3">
                <h3 class="text-md font-medium" v-if="title" :class="titleColor">{{ title }}</h3>
                <div :class="contentColor" class="text-sm" v-if="content">
                    <ul v-if="Array.isArray(v)" role="list" class="list-disc space-y-1 pl-5 ">
                        <li v-for="(item, index) in content" :key="index">{{ item }}</li>
                    </ul>
                    <p v-else class="mt-2">{{ content }}</p>
                </div>
                <div v-if="actions" class="mt-4">
                    <div class="-mx-2 -my-1.5 flex">
                        <button v-for="(action, index) in actions" :key="index" type="button"
                            :class="[buttonBgColor, buttonTextColor]"
                            class="rounded-md px-2 py-1.5 text-sm font-medium hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2"
                            :focus:ring="ringColor" @click="action.onClick">{{ action.text }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { defineProps, computed } from 'vue';
import { ExclamationTriangleIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
    type: {
        type: String,
        default: 'info',
    },
    title: String,
    content: [String, Array],
    actions: Array,
});

const stateStyles = {
    error: {
        bg: 'bg-red-50',
        icon: XCircleIcon,
        iconColor: 'text-red-400',
        titleColor: 'text-red-800',
        contentColor: 'text-red-700',
        buttonBg: 'bg-red-50',
        buttonTextColor: 'text-red-800',
        ringColor: 'ring-red-600',
    },
    info: {
        bg: 'bg-indigo-50',
        icon: InformationCircleIcon,
        iconColor: 'text-indigo-400',
        titleColor: 'text-indigo-800',
        contentColor: 'text-indigo-700',
        buttonBg: 'bg-indigo-50',
        buttonTextColor: 'text-indigo-800',
        ringColor: 'ring-indigo-600',
    },
    success: {
        bg: 'bg-green-100',
        icon: CheckCircleIcon,
        iconColor: 'text-green-400',
        titleColor: 'text-green-700',
        contentColor: 'text-green-700',
        buttonBg: 'bg-green-50',
        buttonTextColor: 'text-green-800',
        ringColor: 'ring-green-600',
    },
    warning: {
        bg: 'bg-yellow-50',
        icon: ExclamationTriangleIcon,
        iconColor: 'text-yellow-400',
        titleColor: 'text-yellow-800',
        contentColor: 'text-yellow-700',
        buttonBg: 'bg-yellow-50',
        buttonTextColor: 'text-yellow-800',
        ringColor: 'ring-yellow-600',
    },
};

const { bg: bgColor, icon, iconColor, titleColor, contentColor, buttonBg: buttonBgColor, buttonTextColor, ringColor } = computed(() => {
    return stateStyles[props.type] || stateStyles.info;
}).value;
</script>