<template>
  <div class="md:flex md:items-center md:justify-between">
    <div class="min-w-0 flex-1 mt-12 mb-12">
      <h2 class="text-2xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">Ma promotion
      </h2>
    </div>
  </div>
  <div>
    <div class="bg-white shadow sm:rounded-lg">
      <div v-if="selectTrainingAction" class="p-6">
        <SelectTrainingAction @confirm="fetchCurrentTrainingActions" />
      </div>
      <div class=" px-4 py-5 sm:p-6" v-else>
        <div class="mt-2 sm:flex sm:items-start sm:justify-between">
          <div>
            <h3 class="text-base font-semibold leading-6 text-gray-900">{{ currentTrainingActions[0]?.degree.name }}
            </h3>
            <div class="font-medium text-indigo-700">{{ currentTrainingActions[0]?.degree.city }} - {{
        currentTrainingActions[0]?.year }}</div>
          </div>
          <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:items-center">
            <router-link :to="'/profil/structures-selectionnees/' + currentTrainingActions[0]?.event_id"
              v-if="currentTrainingActions[0]?.is_pre_event_wishlist_time"><button type="button"
                class="mb-5 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Choisis
                les structures à rencontrer</button></router-link>
            <router-link :to="'/profil/structures-candidature/' + currentTrainingActions[0]?.event_id"
              v-if="currentTrainingActions[0]?.is_post_event_wishlist_time"><button type="button"
                class="mb-5 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Fais
                tes vœux de structure</button></router-link>
          </div>
        </div>
        <div class="overflow-hidden rounded-full bg-gray-200 my-5 hidden lg:block">
          <div class="h-2 rounded-full bg-indigo-600" :style="'width:' + widthProgressBar" />
        </div>
        <div class="">
          <nav class="mx-auto" aria-label="Progress">
            <ol role="list" class="overflow-hidden rounded-md lg:flex lg:rounded-none">
              <li v-for="( step, stepIdx ) in  steps " :key="step.id" class="relative overflow-hidden lg:flex-1">
                <div
                  :class="[stepIdx === 0 ? 'rounded-t-md border-b-0' : '', stepIdx === steps.length - 1 ? 'rounded-b-md border-t-0' : '', 'overflow-hidden border lg:border-0']">
                  <a v-if="step.status === 'complete'" :href="step.href" class="group">
                    <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'flex items-start px-6 py-5 text-sm font-medium']">
                      <span class="flex-shrink-0">
                        <span class="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600">
                          <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </span>
                      <span class="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span class="text-sm font-semibold  text-indigo-600">{{ step.name }}</span>
                        <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>
                      </span>
                    </span>
                  </a>
                  <a v-else-if="step.status === 'current'" :href="step.href" aria-current="step">
                    <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'flex items-start px-6 py-5 text-sm font-medium']">
                      <span class="flex-shrink-0">
                        <span
                          class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-indigo-600">
                          <span class="text-indigo-600">{{ step.id }}</span>
                        </span>
                      </span>
                      <span class="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span class="text-sm  font-semibold text-indigo-600">{{ step.name }}</span>
                        <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>
                      </span>
                    </span>
                  </a>
                  <a v-else :href="step.href" class="group">
                    <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'flex items-start px-6 py-5 text-sm font-medium']">
                      <span class="flex-shrink-0">
                        <span class="flex h-10 w-10 items-center justify-center rounded-full border-2">
                          <span class="text-gray-500">{{ step.id }}</span>
                        </span>
                      </span>
                      <span class="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span class="text-sm font-semibold  text-indigo-600">{{ step.name }}</span>
                        <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>
                      </span>
                    </span>
                  </a>
                  <template v-if="stepIdx !== 0">
                    <!-- Separator -->
                    <div class="absolute inset-0 left-0 top-0 hidden w-3 lg:block" aria-hidden="true">
                    </div>
                  </template>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <AlertMessage v-if="displayAlert" :type="alertType" :title="alertMessage" :content="alertContent" class="mb-5" />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, inject } from 'vue';
import client from '@/api/client';
import { CheckIcon } from '@heroicons/vue/24/solid';
import WishlistServices from '@/services/WishlistServices.js';
import SelectTrainingAction from '@/components/training-actions/SelectTrainingAction.vue';
import AlertMessage from '@/components/alert/AlertMessage.vue';

const store = inject('store');
const currentProfile = store.chosenProfile;

const profileId = store.chosenProfile.id;
const currentTrainingActions = ref([]);
const widthProgressBar = ref('0%');
const selectTrainingAction = ref(false);
const currentAction = ref(null);
const alertMessage = ref('');
const wishlistsUser = ref([]);
const totalWhishlists = ref(0);
const alertType = ref('info');
const displayAlert = ref(false);
const alertContent = ref('');

const steps = [
  { id: '01', name: 'Vœux rencontre', description: 'Je choisis les structures que je souhaite rencontrer lors de l\'Alternance Day', href: '/profil/structures-selectionnees', status: 'upcoming' },
  { id: '02', name: 'Alternance Day', description: 'Je rencontre les structures', href: '/prochain-evenement', status: 'upcoming' },
  { id: '03', name: 'Vœux alternance', description: 'Je fais mes vœux de structure pour y réaliser mon alternance', href: 'profil/structures-candidature/', status: 'upcoming' },
  { id: '04', name: 'Début de la formation', description: 'Je commence la formation dans la structure qui m\'a été attribué', href: '#', status: 'upcoming' },
]

const fetchCurrentTrainingActions = async () => {
  const response = await client.get(`/api/training-actions/profile/${profileId}`);
  currentTrainingActions.value = response.data;
  if (currentTrainingActions.value[0]) { fetchUserWishlists(currentTrainingActions.value[0].event_id); }
  if (currentTrainingActions.value.length === 0) {
    selectTrainingAction.value = true;
  } else {
    selectTrainingAction.value = false;
    if (currentTrainingActions.value[0].is_pre_event_wishlist_time) {
      steps[0].status = 'current';
      steps[1].status = 'upcoming';
      steps[2].status = 'upcoming';
      steps[3].status = 'upcoming';
      widthProgressBar.value = '25%';
      currentAction.value = currentTrainingActions.value[0];
    }
    if (currentTrainingActions.value[0].is_post_event_wishlist_time) {
      steps[0].status = 'complete';
      steps[1].status = 'complete';
      steps[2].status = 'current';
      steps[3].status = 'upcoming';
      widthProgressBar.value = '65%';
    }
    if (currentTrainingActions.value[0].is_ongoing) {
      steps[0].status = 'complete';
      steps[1].status = 'complete';
      steps[2].status = 'complete';
      steps[3].status = 'complete';
      widthProgressBar.value = '100%';
    }
  }
};

const fetchUserWishlists = async (eventId) => {
  const eventType = currentTrainingActions.value[0].is_pre_event_wishlist_time ? '1' : '0';
  wishlistsUser.value = await WishlistServices.fetchWishlistsByUserAndEvent(currentProfile.id, eventId, eventType);
  totalWhishlists.value = wishlistsUser.value.length;
  const initialChoices = wishlistsUser.value.filter(s => s.is_initial_choice).length;
  const optionalChoices = wishlistsUser.value.filter(s => s.is_optional_choice).length;

  if (initialChoices === 3 && optionalChoices == 2) {

  }
  if (totalWhishlists.value === 5) {
    displayAlert.value = true;
    alertMessage.value = 'Bravo, tu as bien enregistré tous tes vœux !';
    alertType.value = 'success';
  } else {
    const remainingWishlists = 5 - totalWhishlists.value;
    displayAlert.value = true;
    alertMessage.value = `Tu as enregistré ${totalWhishlists.value} vœux. `
    alertMessage.value += `Il te reste ${remainingWishlists} vœu`;
    if (remainingWishlists > 1) {
      alertMessage.value += 'x';
    }
    alertMessage.value += ' à enregistrer.';
    alertType.value = 'info';
  }
};

onMounted(() => {
  fetchCurrentTrainingActions();
});

</script>
