<template>
    <div class="">
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h2 class="text-base font-semibold leading-6 text-gray-900">Whishlists</h2>
                <p class="mt-2 text-sm text-gray-700">Liste des vœux enregistrés</p>
            </div>
        </div>
        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Apprenant
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Structures sélectionnées
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Détail
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for=" relevantWhishlist in relevantWhishlists" :key="relevantWhishlist?.id"
                                class="even:bg-gray-50">
                                <td class="px-3 pl-0 py-4 text-sm text-indigo-700 font-medium">{{
                                relevantWhishlist?.profile_name }}</td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm font-normal">
                                    <div v-for="profileWishlist in relevantWhishlist.wishlists" class="mb-1"
                                        :key="profileWishlist.id">
                                        {{ profileWishlist.structure.name }}
                                        <span
                                            class="ml-2 inline-flex items-center rounded-full bg-green-50 px-2 py-0 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                                            v-if="profileWishlist?.is_initial_choice === 1">prioritaire</span>
                                    </div>
                                </td>
                                <td
                                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium cursor-pointer">
                                    <router-link
                                        :to="'/apprenant-voeux/' + relevantWhishlist.profile_id + '/' + eventSelected">
                                        <button
                                            class="text-indigo-600 hover:text-indigo-900">Voir</button></router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <AdminDialog @confirm="editItem" :typeEdition="typeEdition" :title="confirmTexts[1].title"
        @close="displayEditDialog = false" v-model="typeModelForm" :objectToModify="dataToModify"
        v-if="displayEditDialog" />
    <ConfirmationDialog @confirm="deleteItem(idTypeToEdit)" v-if="displayConfirmDeleteDialog"
        :title="confirmTexts[0].title" :message="confirmTexts[0].message"
        :primaryActionLabel="confirmTexts[0].primaryActionLabel"
        :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed, inject } from 'vue';
import client from '@/api/client';
import { useRoute } from 'vue-router';
import AdminDialog from '@/components/dialogs/AdminDialog.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import fetchWishlistsByEvent from '@/services/WishlistServices.js';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const cantEditItems = computed(() => (currentProfile.value.role.name === 'Formateur·trice référent·e'));

const relevantWhishlists = ref([]);

const route = useRoute();
const displayEditDialog = ref(false);
const displayConfirmDeleteDialog = ref(false);
const typeEdition = ref('creation');
const idTypeToEdit = ref(null);
const dataToModify = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const errorMessage = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = 20;
const eventSelected = ref(1);
// Todo : add a select to select event

const confirmTexts = [
    {
        title: 'Supprimer cet item ?',
        message: 'Voulez-vous supprimer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
    {
        title: 'Éditer une whishlist',
        message: 'Voulez-vous éditer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
]
const typeModelForm = ref([
    {
        field: 'name',
        label: 'Nom',
        type: 'text',
        entry: '',
    },
    {
        field: 'active',
        label: 'Actif',
        type: 'boolean',
        entry: '',
    },
]);

const editItem = async (arrayToPost) => {
    const typeFormData = arrayToPost.value.reduce((formData, item) => {
        formData[item.field] = item.entry;
        return formData;
    }, {});

    if (typeEdition.value === 'creation') {
        createItem(typeFormData);
    } else {
        modifyItem(typeFormData);
    }
};

const createItem = async (typeToCreate) => {
    displayEditDialog.value = false;
    try {
        const response = await client.post(`/api/whishlists`, typeToCreate);
        relevantWhishlists.value = fetchWishlistsByEvent.fetchWishlists();
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
};

const modifyItem = async (typeToModify) => {
    console.log(typeToModify[0]);
    displayEditDialog.value = false;
    try {
        const response = await client.put(`/api/whishlists/${idTypeToEdit.value}`, typeToModify);
        relevantWhishlists.value = fetchWishlistsByEvent.fetchWishlists();
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre situation';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    };
}

const deleteItem = async (typeToDelete) => {
    displayConfirmDeleteDialog.value = false;
    try {
        const response = await client.delete(`/api/whishlists/${idTypeToEdit.value}`);
        relevantWhishlists.value = fetchWishlistsByEvent.fetchWishlists();
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
};
const changePage = (page) => {
    client.get('/api/wishlists', {
        params: { page, per_page: pageSize },
    }).then((response) => {
        relevantWhishlists.value = response.data.data;
        currentPage.value = response.data.current_page;
    });
};

const filterWishlists = async (event) => {
    eventSelected.value = event;
    try {
        const response = await client.get('/api/wishlists', {
            params: { page: 1, per_page: pageSize },
        });
        relevantWhishlists.value = response.data.data;
        currentPage.value = response.data.current_page;
        totalPages.value = response.data.last_page;
        totalItems.value = response.data.total;
    } catch (error) {
        console.error('Failed to fetch the next event:', error);
    }
};
// Todo: use filterWishlists function to add an advanced search view to filter wishlists by structure or others filter

onMounted(async () => {
    try {
        const response = await client.get(`/api/wishlists/event/${eventSelected.value}/group-by-profile`);
        relevantWhishlists.value = response.data;
    } catch (error) {
        console.error('Failed to fetch the next event:', error);
    }
});
</script>
