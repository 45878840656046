<template>
    <div class="mx-auto 2xl:max-w-7xl detail-page !px-0 lg:px-8">
        <div class="">
            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h2 class="text-xl text-indigo-700 font-semibold leading-6 text-gray-900">Associez des structures à
                        des zones
                    </h2>
                    <p class="mt-2 text-sm text-gray-700">Liste des participations de structures enregistrées</p>
                </div>
                <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <router-link to=""> <button type="button"
                            @click="displayEditDialog = true; typeEdition = 'creation'; refreshModelForm();"
                            class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Inscrire
                            une structure</button></router-link>
                </div>
            </div>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col"
                                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Zone
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Structures
                                    </th>
                                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span class="sr-only">Edition</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="relevantParticipation in relevantEvent.participations"
                                    :key="relevantParticipation.id" class="even:bg-gray-50">
                                    <td class="whitespace-nowrap px-3 pl-0 py-4 text-sm text-indigo-700 font-medium">

                                        <p class="text-black"> {{ relevantParticipation.zone.name }}</p>
                                        <p>{{ relevantParticipation.zone.region }}</p>
                                    </td>

                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-indigo-700 font-medium">
                                        {{
                                relevantParticipation.structure.name }}</td>
                                    <td
                                        class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium">
                                        <button class="text-indigo-600 hover:text-indigo-900"
                                            @click="displayEditDialog = true; typeEdition = 'modification'; idTypeToEdit = relevantParticipation.id; dataToModify = relevantParticipation">Modifier</button>
                                    </td>
                                    <td
                                        class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium">
                                        <button class="text-indigo-600 hover:text-indigo-900"
                                            @click="displayConfirmDeleteDialog = true; typeEdition = 'creation'; idTypeToEdit = relevantParticipation.id;">
                                            Supprimer</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <AdminDialogParticipations @confirm="editItem" :zones="relevantEvent.zones" :typeEdition="typeEdition"
        :size="'fullscreen'" :title="confirmTexts[1].title" @close="displayEditDialog = false" v-model="typeModelForm"
        :objectToModify="dataToModify" v-if="displayEditDialog" :key="'participations'" />
    <ConfirmationDialog @confirm="deleteItem(idTypeToEdit)" v-if="displayConfirmDeleteDialog"
        :title="confirmTexts[0].title" :message="confirmTexts[0].message"
        :primaryActionLabel="confirmTexts[0].primaryActionLabel"
        :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />

</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import client from '@/api/client';
import AdminDialogParticipations from '@/components/events/AdminDialogParticipations.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import fetchEventServices from '@/services/EventServices.js';
// import { CalendarDaysIcon } from '@heroicons/vue/20/solid';
import { CalendarDaysIcon, MapPinIcon, ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline';

const route = useRoute();
const displayEditDialog = ref(false);
const displayConfirmDeleteDialog = ref(false);
const typeEdition = ref('creation');
const idTypeToEdit = ref(null);
const dataToModify = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const errorMessage = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');

const relevantEvent = defineModel();

const confirmTexts = [
    {
        title: 'Supprimer cet item ?',
        message: 'Voulez-vous supprimer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
    {
        title: 'Éditer une participation',
        message: 'Voulez-vous éditer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
]
const typeModelForm = ref([]);

const refreshModelForm = () => {
    typeModelForm.value = {
        structure_id: null,
        zone_id: null,
        event_id: route.params.id,
    };
};

const editItem = async (arrayToPost) => {
    const typeFormData = arrayToPost.value;

    if (typeEdition.value === 'creation') {
        createItem(typeFormData);
    } else {
        modifyItem(typeFormData);
    }
};

const createItem = async (typeToCreate) => {
    displayEditDialog.value = false;
    try {
        const response = await client.post(`/api/participations`, typeToCreate);
        const responseEvent = await client.get(`api/events/${route.params.id}/related`);
        relevantEvent.value = responseEvent.data.event;
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre événement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
};

const modifyItem = async (typeToModify) => {
    displayEditDialog.value = false;
    try {
        await client.put(`/api/participations/${idTypeToEdit.value}`, typeToModify);
        const responseEvent = await client.get(`api/events/${route.params.id}/related`);
        relevantEvent.value = responseEvent.data.event;
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre événement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    };
}

const deleteItem = async () => {
    displayConfirmDeleteDialog.value = false;
    try {
        const response = await client.delete(`/api/participations/${idTypeToEdit.value}`);
        const responseEvent = await client.get(`api/events/${route.params.id}/related`);
        relevantEvent.value = responseEvent.data.event;
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre événement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
};
onMounted(async () => {

});

</script>
