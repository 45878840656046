<template>
    <div class="">
        {{ currentProfile?.bio }}
        <CurrentTrainingActions />
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, inject, watch, computed, onMounted } from 'vue';
import CurrentTrainingActions from '@/components/training-actions/CurrentTrainingActions.vue';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);

watch(() => store.chosenProfile, (newProfile) => {
    currentProfile.value = newProfile;
});

onMounted(async () => {
});
</script>
