<template>
    <div class="">
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h2 class="text-base font-semibold leading-6 text-gray-900">{{ totalItems }} Structures</h2>
                <p class="mt-2 text-sm text-gray-700">Liste des structures enregistrées</p>
            </div>
            <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <router-link to="/structures/creer-ma-structure" v-if="!cantEditItems"> <button type="button"
                        class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Créer
                        une structure</button></router-link>
            </div>
        </div>
        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Nom
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Actif
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Statut
                                    légal
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Publics
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Profils
                                </th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span class="sr-only">Page publique</span>
                                </th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span class="sr-only">Edition</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="relevantStructure in relevantStructures" :key="relevantStructure.id"
                                class="even:bg-gray-50">
                                <td class="pr-3 py-4 px-3 ">
                                    <p class="text-md text-indigo-700 font-medium">{{
                    relevantStructure.name }}</p>
                                    <p class="text-sm font-normal"> {{
                    relevantStructure.organization.name }}</p>
                                </td>

                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                                        v-if="relevantStructure.is_active === 1">actif</span><span v-else
                                        class="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">inactif</span>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{
                    relevantStructure.legal_form.name }}</td>
                                <td class="px-3 py-4 text-sm text-gray-500 w-64">

                                    <span v-for="(attachedType, index) in relevantStructure.types"
                                        :key="attachedType.id"> {{
                    attachedType.name
                }}<span v-if="index < relevantStructure.types.length - 1">,
                                            &NonBreakingSpace;</span>
                                    </span>
                                </td>
                                <td class="px-3 py-4 text-sm text-gray-500 w-64">

                                    <span v-for="(attachedPublic, index) in relevantStructure.publics"
                                        :key="attachedPublic.id"> {{
                    attachedPublic.name
                }}<span v-if="index < relevantStructure.types.length - 1">,
                                            &NonBreakingSpace;</span>
                                    </span>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <span
                                        class="inline-flex items-center rounded-full bg-indigo-50 px-1.5 py-0.5 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                        {{
                    Object.keys(relevantStructure.attachedProfiles).length
                }}</span>
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium">
                                    <router-link :to="'/structures/' + relevantStructure.id" class="text-indigo-600
                                        hover:text-indigo-900">Voir<span class="sr-only">, {{ relevantStructure.name
                                            }}</span></router-link>
                                </td>
                                <td class="relative whitespace-nowrap py-4 px-3 pr-4 text-right text-sm font-medium">
                                    <router-link :to="'/structures/modifier-ma-structure/' + relevantStructure.id"
                                        v-if="!cantEditItems"
                                        class="text-indigo-600 hover:text-indigo-900">Modifier<span class="sr-only">, {{
                    relevantStructure.name }}</span></router-link>
                                </td>
                                <td class="relative whitespace-nowrap py-4 px-3 pr-4 text-right text-sm font-medium cursor-pointer"
                                    v-if="!cantEditItems">
                                    <button class="text-indigo-600 hover:text-indigo-900"
                                        @click="displayConfirmDeleteDialog = true; typeEdition = 'creation'; idTypeToEdit = relevantStructure.id;">
                                        supprimer</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
                </div>
            </div>
        </div>
    </div>
    <ConfirmationDialog @confirm="deleteItem(idTypeToEdit)" v-if="displayConfirmDeleteDialog"
        :title="confirmTexts[0].title" :message="confirmTexts[0].message"
        :primaryActionLabel="confirmTexts[0].primaryActionLabel"
        :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed, inject } from 'vue';
import client from '@/api/client';
import Pagination from '@/components/lists/PaginationTables.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);

const currentPage = ref(1);
const totalPages = ref(1);
const pageSize = 10;
const totalItems = ref(0);

const typeEdition = ref('creation');
const idTypeToEdit = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const displayConfirmDeleteDialog = ref(false);

const relevantStructures = ref([]);
const cantEditItems = computed(() => (currentProfile.value.role.name === 'Formateur·trice référent·e'));

const confirmTexts = [
    {
        title: 'Supprimer cet item ?',
        message: 'Voulez-vous supprimer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
]

const fetchStructures = async (page = 1) => {
    try {
        const response = await client.get('/api/structures/search/filter?', {
            params: { page, per_page: pageSize },
        });
        relevantStructures.value = response.data.data;
        currentPage.value = response.data.current_page;
        totalPages.value = response.data.last_page;
        totalItems.value = response.data.total;
    } catch (error) {
        console.error('Failed to fetch structures :', error);
    }
};

const deleteItem = async () => {
    console.log('deleteItem');
    displayConfirmDeleteDialog.value = false;
    try {
        const response = await client.delete(`/api/structures/${idTypeToEdit.value}`);
        changePage(currentPage.value);
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre événement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
};

const changePage = (page) => {
    fetchStructures(page);
};

onMounted(() => {
    fetchStructures();
});

</script>