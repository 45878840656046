<template>
    <div>
        <div class="">
            <router-link to="">
                <button type="button" @click="openCreationDialog"
                    class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Créer une organisation
                </button>
            </router-link>
        </div>
        <AdminDialog @confirm="editItem" :typeEdition="typeEdition" :title="confirmTexts[1].title"
            @close="closeEditDialog" v-model="typeModelForm" :objectToModify="dataToModify" v-if="displayEditDialog" />
        <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
            :detail="notificationMessage" @close="showNotification = false" />
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, watch } from 'vue';
import client from '@/api/client';
import AdminDialog from '@/components/dialogs/AdminDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

const displayEditDialog = ref(false);
const typeEdition = ref('creation');
const dataToModify = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');

const emit = defineEmits(['update', 'update:modelValue']);

const typeModelForm = ref([
    {
        field: 'name',
        label: 'Nom',
        type: 'text',
        entry: '',
    },
]);

watch(typeModelForm, (newVal) => {
    console.log('typeModelForm changed:', newVal); // Debugging
});

const confirmTexts = [
    {
        title: 'Supprimer cet item ?',
        message: 'Voulez-vous supprimer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler',
    },
    {
        title: 'Éditer une organisation',
        message: 'Voulez-vous éditer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler',
    },
];

const createItem = async (typeToCreate) => {
    displayEditDialog.value = false;
    try {
        const response = await client.post('/api/organizations', typeToCreate);
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        emit('update', response.data);
    } catch (error) {
        console.error('Failed to create organization:', error);
        if (error.response && error.response.data) {
            console.error('Server response:', error.response.data);
        }
        showNotification.value = true;
        notificationType.value = 'error';
        notificationTitle.value = 'Creation Error';
        notificationMessage.value = `Failed to create organization. ${error.response && error.response.data.message ? error.response.data.message : ''}`;
    }
};

const modifyItem = async (typeToModify) => {
    displayEditDialog.value = false;
    try {
        const response = await client.put(`/api/organizations/${typeToModify.id}`, typeToModify);
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        console.error('Failed to modify organization:', error);
        if (error.response && error.response.data) {
            console.error('Server response:', error.response.data);
        }
        showNotification.value = true;
        notificationType.value = 'error';
        notificationTitle.value = 'Modification Error';
        notificationMessage.value = `Failed to modify organization. ${error.response && error.response.data.message ? error.response.data.message : ''}`;
    }
};

const editItem = async (arrayToPost) => {
    const typeFormData = arrayToPost.value.reduce((formData, item) => {
        formData[item.field] = item.entry;
        return formData;
    }, {});

    console.log('typeFormData:', typeFormData); // Debugging

    if (typeEdition.value === 'creation') {
        await createItem(typeFormData);
    } else {
        await modifyItem(typeFormData);
    }
};

const refreshModelForm = () => {
    typeModelForm.value = typeModelForm.value.map((item) => ({
        ...item,
        entry: '',
    }));
    console.log('Model form refreshed:', typeModelForm.value); // Debugging
};

const openCreationDialog = () => {
    console.log('openCreationDialog called'); // Debugging
    displayEditDialog.value = true;
    typeEdition.value = 'creation';
    refreshModelForm();
};

const closeEditDialog = () => {
    console.log('closeEditDialog called'); // Debugging
    displayEditDialog.value = false;
};
</script>