<template>
    <AppLayout>
        <StructureForm class="mb-12" :context="editMode" />
    </AppLayout>
</template>

<script setup>
import { ref } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import StructureForm from '@/components/structures/StructureForm.vue';

const editMode = ref('structureCreation');
</script>
