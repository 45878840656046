import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store/store';
import './assets/css/main.css';

const app = createApp(App);

app.provide('store', store);

app.use(router).mount('#app');
