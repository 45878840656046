<template>
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-10" @close="$emit('close')">

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <TransitionChild as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <div class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                    <div class="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                                        <div class="px-4 sm:px-6">
                                            <div class="flex items-start justify-between">
                                                <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                                                    Filtrer
                                                </DialogTitle>
                                                <div class="ml-3 flex h-7 items-center">
                                                    <button type="button"
                                                        class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                        @click="$emit('close')">
                                                        <span class="absolute -inset-2.5" />
                                                        <span class="sr-only">Fermer</span>
                                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="relative mt-6 flex-1 px-4 sm:px-6">
                                            <div class="relative mt-2 rounded-md shadow-sm">
                                                <div
                                                    class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                    <MagnifyingGlassIcon class="h-5 w-5 text-gray-400"
                                                        aria-hidden="true" />
                                                </div>
                                                <input type="email" name="email" id="email"
                                                    v-model="paramsSearch.keywords" @change="searchStructures"
                                                    class="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    placeholder="Rechercher" />
                                            </div>
                                            <!-- Filters -->
                                            <form class="mt-4">
                                                <Disclosure as="div" class="border-b border-gray-200 px-4 py-6"
                                                    v-slot="{ open }">
                                                    <h3 class="-mx-2 -my-3 flow-root">
                                                        <DisclosureButton
                                                            class="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                                            <span class="font-medium text-gray-900 flex items-center">
                                                                <component :is="UserIcon" class="h-5 w-5 mr-2"
                                                                    aria-hidden="true" />Publics
                                                            </span>
                                                            <span class="ml-6 flex items-center">
                                                                <PlusIcon v-if="!open" class="h-5 w-5"
                                                                    aria-hidden="true" />
                                                                <MinusIcon v-else class="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        </DisclosureButton>
                                                    </h3>
                                                    <DisclosurePanel class="pt-6">

                                                        <div class="space-y-6">
                                                            <div v-for="(option, optionIdx) in relevantPublics"
                                                                :key="option.id" class="flex items-center">
                                                                <input :id="`filter-mobile-public-${optionIdx}`"
                                                                    @change="searchStructures"
                                                                    v-model="paramsSearch.publics" :name="`public[]`"
                                                                    :value="option.id" type="checkbox"
                                                                    :checked="option.checked"
                                                                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                                                <label :for="`filter-mobile-public-${optionIdx}`"
                                                                    class="ml-3 min-w-0 flex-1 text-gray-500">{{
        option.name
    }}</label>
                                                            </div>
                                                        </div>
                                                    </DisclosurePanel>
                                                </Disclosure>
                                                <Disclosure as="div" class="border-b border-gray-200 px-4 py-6"
                                                    v-slot="{ open }">
                                                    <h3 class="-mx-2 -my-3 flow-root">
                                                        <DisclosureButton
                                                            class="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                                            <span class="font-medium text-gray-900 flex items-center">
                                                                <component :is="AcademicCapIcon" class="h-5 w-5 mr-2"
                                                                    aria-hidden="true" />Diplômes
                                                            </span>
                                                            <span class="ml-6 flex items-center">
                                                                <PlusIcon v-if="!open" class="h-5 w-5"
                                                                    aria-hidden="true" />
                                                                <MinusIcon v-else class="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        </DisclosureButton>
                                                    </h3>
                                                    <DisclosurePanel class="pt-6">

                                                        <div class="space-y-6">
                                                            <div v-for="(option, optionIdx) in relevantDegrees"
                                                                :key="option.id" class="flex items-center">
                                                                <input :id="`filter-degrees-${optionIdx}`"
                                                                    v-model="paramsSearch.degrees" :name="`degree[]`"
                                                                    @change="searchStructures" :value="option.id"
                                                                    type="checkbox" :checked="option.checked"
                                                                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                                                <label :for="`filter-degrees-${optionIdx}`"
                                                                    class="ml-3 min-w-0 flex-1 text-gray-500">{{
        option.name
    }}</label>
                                                            </div>
                                                        </div>
                                                    </DisclosurePanel>
                                                </Disclosure>
                                                <Disclosure as="div" class="border-b border-gray-200 px-4 py-6"
                                                    v-slot="{ open }">
                                                    <h3 class="-mx-2 -my-3 flow-root">
                                                        <DisclosureButton
                                                            class="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                                            <span class="font-medium text-gray-900 flex items-center">
                                                                <component :is="TagIcon" class="h-5 w-5 mr-2"
                                                                    aria-hidden="true" />Statuts
                                                            </span>
                                                            <span class="ml-6 flex items-center">
                                                                <PlusIcon v-if="!open" class="h-5 w-5"
                                                                    aria-hidden="true" />
                                                                <MinusIcon v-else class="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        </DisclosureButton>
                                                    </h3>
                                                    <DisclosurePanel class="pt-6">

                                                        <div class="space-y-6">
                                                            <div v-for="(option, optionIdx) in relevantLegalForms"
                                                                :key="option.id" class="flex items-center">
                                                                <input :id="`filter-legal-form-${optionIdx}`"
                                                                    v-model="paramsSearch.legalForms"
                                                                    :name="`legal_fomr[]`" @change="searchStructures"
                                                                    :value="option.id" type="checkbox"
                                                                    :checked="option.checked"
                                                                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                                                <label :for="`filter-legal-form-${optionIdx}`"
                                                                    class="ml-3 min-w-0 flex-1 text-gray-500">{{
        option.name
    }}</label>
                                                            </div>
                                                        </div>
                                                    </DisclosurePanel>
                                                </Disclosure>
                                                <Disclosure as="div" class="border-b border-gray-200 px-4 py-6"
                                                    v-slot="{ open }">
                                                    <h3 class="-mx-2 -my-3 flow-root">
                                                        <DisclosureButton
                                                            class="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                                            <span class="font-medium text-gray-900 flex items-center">
                                                                <component :is="HomeIcon" class="h-5 w-5 mr-2"
                                                                    aria-hidden="true" />Types de structures
                                                            </span>
                                                            <span class="ml-6 flex items-center">
                                                                <PlusIcon v-if="!open" class="h-5 w-5"
                                                                    aria-hidden="true" />
                                                                <MinusIcon v-else class="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        </DisclosureButton>
                                                    </h3>
                                                    <DisclosurePanel class="pt-6">

                                                        <div class="space-y-6">
                                                            <div v-for="(option, optionIdx) in relevantTypes"
                                                                :key="option.id" class="flex items-center">
                                                                <input :id="`filter-types-${optionIdx}`"
                                                                    v-model="paramsSearch.types" :name="`types[]`"
                                                                    @change="searchStructures" :value="option.id"
                                                                    type="checkbox" :checked="option.checked"
                                                                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                                                <label :for="`filter-types-${optionIdx}`"
                                                                    class="ml-3 min-w-0 flex-1 text-gray-500">{{
                                                                    option.name
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                    </DisclosurePanel>
                                                </Disclosure>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="flex flex-shrink-0 justify-end px-4 py-4">
                                        <button type="button"
                                            class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                                            @click="$emit('close')">Annuler</button>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon, MagnifyingGlassIcon, AcademicCapIcon, MapPinIcon, HomeIcon, TagIcon, UserIcon } from '@heroicons/vue/24/outline';
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from '@headlessui/vue';
import { PlusIcon, MinusIcon } from '@heroicons/vue/20/solid';
import fetchServices from '@/services/fetchServices.js';
import client from '@/api/client';

const model = defineModel();
const emit = defineEmits(['update:modelValue', 'close', 'update']);

defineProps({
    required: true,
});
const open = ref(true);

const relevantPublics = ref([]);
const relevantDegrees = ref([]);
const relevantLegalForms = ref([]);
const relevantTypes = ref([]);

const paramsSearch = ref({
    publics: [],
    degrees: [],
    keywords: [],
    types: [],
    legalForms: [],
});

const searchStructures = () => {
    emit('update:modelValue', paramsSearch.value);
    emit('update', paramsSearch.value);
};

onMounted(() => {
    fetchServices.fetchStructuresPublics().then((data) => {
        relevantPublics.value = data;
    });
    fetchServices.fetchDegreesPublics().then((data) => {
        relevantDegrees.value = data;
    });
    fetchServices.fetchLegalFormsPublics().then((data) => {
        relevantLegalForms.value = data;
    });
    fetchServices.fetchTypesPublics().then((data) => {
        relevantTypes.value = data;
    });
    paramsSearch.value = model.value;
});
</script>
