<template>
    <div class="">
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h2 class="text-base font-semibold leading-6 text-gray-900">Organisations</h2>
                <p class="mt-2 text-sm text-gray-700">Liste des organisations enregistrées</p>
            </div>
            <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button type="button" v-if="!cantEditItems"
                    @click="displayEditDialog = true; typeEdition = 'creation'; refreshModelForm();"
                    class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Créer
                    une organisation</button>
            </div>
        </div>
        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Nom
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Actif
                                </th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span class="sr-only">Edition</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="relevantOrganization in relevantOrganizations" :key="relevantOrganization.id"
                                class="even:bg-gray-50">
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-indigo-700 font-medium">{{
                    relevantOrganization.name }}</td>

                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                                        v-if="relevantOrganization.active">actif</span><span v-else
                                        class="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">inactif</span>
                                </td>

                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium"
                                    v-if="!cantEditItems">
                                    <button class="text-indigo-600 hover:text-indigo-900"
                                        @click="displayEditDialog = true; typeEdition = 'modification'; idTypeToEdit = relevantOrganization.id; dataToModify = relevantOrganization">Modifier
                                    </button>
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium"
                                    v-if="!cantEditItems">
                                    <button class="text-indigo-600 hover:text-indigo-900"
                                        @click="displayConfirmDeleteDialog = true; typeEdition = 'creation'; idTypeToEdit = relevantOrganization.id;">Supprimer</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <AdminDialog @confirm="editItem" :typeEdition="typeEdition" :title="confirmTexts[1].title"
        @close="displayEditDialog = false" v-model="typeModelForm" :objectToModify="dataToModify"
        v-if="displayEditDialog" />
    <ConfirmationDialog @confirm="deleteItem(idTypeToEdit)" v-if="displayConfirmDeleteDialog"
        :title="confirmTexts[0].title" :message="confirmTexts[0].message"
        :primaryActionLabel="confirmTexts[0].primaryActionLabel"
        :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed, inject } from 'vue';
import client from '@/api/client';
import AdminDialog from '@/components/dialogs/AdminDialog.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const cantEditItems = computed(() => (currentProfile.value.role.name === 'Formateur·trice référent·e'));

const relevantOrganizations = ref([]);

const displayEditDialog = ref(false);
const displayConfirmDeleteDialog = ref(false);
const typeEdition = ref('creation');
const idTypeToEdit = ref(null);
const dataToModify = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const errorMessage = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');

const confirmTexts = [
    {
        title: 'Supprimer cet item ?',
        message: 'Voulez-vous supprimer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
    {
        title: 'Éditer une organisation',
        message: 'Voulez-vous éditer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
]
const typeModelForm = ref([
    {
        field: 'name',
        label: 'Nom',
        type: 'text',
        entry: '',
    },
    {
        field: 'active',
        label: 'Actif',
        type: 'boolean',
        entry: '',
    },
]);

const refreshModelForm = () => {
    typeModelForm.value.forEach((item) => {
        item.entry = '';
    });
};

const fetchOrganizations = async () => {
    try {
        const response = await client.get(`/api/organizations`);
        relevantOrganizations.value = response.data;
    } catch (error) {
        console.error('Failed to fetch organizations :', error);
    }
};
const editItem = async (arrayToPost) => {
    const typeFormData = arrayToPost.value.reduce((formData, item) => {
        formData[item.field] = item.entry;
        return formData;
    }, {});

    if (typeEdition.value === 'creation') {
        createItem(typeFormData);
    } else {
        modifyItem(typeFormData);
    }
};

const createItem = async (typeToCreate) => {
    displayEditDialog.value = false;
    try {
        const response = await client.post(`/api/organizations`, typeToCreate);
        fetchOrganizations();
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
};

const modifyItem = async (typeToModify) => {
    console.log(typeToModify[0]);
    displayEditDialog.value = false;
    try {
        const response = await client.put(`/api/organizations/${idTypeToEdit.value}`, typeToModify);
        fetchOrganizations();
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre situation';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    };
}

const deleteItem = async (typeToDelete) => {
    displayConfirmDeleteDialog.value = false;
    try {
        const response = await client.delete(`/api/organizations/${idTypeToEdit.value}`);
        fetchOrganizations();
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
};

onMounted(() => {
    fetchOrganizations();
});

</script>
