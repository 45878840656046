<template>

  <TransitionRoot as="template" :show="open">
    <Dialog class="relative z-10" @close="closeDialog">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              :class="[size === 'fullscreen' ? 'w-full rounded-none' : 'sm:my-8 sm:max-w-lg', 'relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all  sm:w-full  sm:p-6']">
              <div class="container mx-auto max-w-5xl p-6">
                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button type="button"
                    class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    @click="closeDialog">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex items-center mb-10">
                  <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilSquareIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                    <DialogTitle as="h3" class="text-lg font-semibold w-full leading-6 text-gray-900">
                      Pour sélectionner des structures, tu dois choisir le diplôme que tu souhaites suivre
                    </DialogTitle>

                  </div>
                </div>
                <div>
                  <router-link to="/tableau-de-bord"><button class="btn-primary">Sélectionner une
                      promo</button></router-link>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, reactive, onMounted, watch, inject } from 'vue';
import { useRoute } from 'vue-router';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { PencilSquareIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';

const route = useRoute();

const store = inject('store');
const open = ref(true);
const emit = defineEmits(['confirm', 'close', 'update:modelValue']);

const itemToEdit = defineModel();
const props = defineProps({
  typeEdition: String,
  title: String,
  objectToModify: [Array, Object],
  size: String,
});
const enabled = ref(false);

const closeDialog = () => {
  emit('close');
};

const confirmAction = (trainingAction) => {
  console.log('trainingAction', trainingAction.event_id);
  router.push({ path: '/profil/structures-selectionnees/', params: { eventId: trainingAction.event_id } });
  emit('confirm');
};

onMounted(() => {
});

</script>