<template>
    <div class="min-h-screen flex items-center flex-col justify-center bg-gray-100">
        <div class="mb-10"><a href="/" alt="logo Trajectoire fest"><img class="h-12 sm:h-16"
                    src="@/assets/img/logos/logo-trajectoire.svg" alt="Trajecoire Fest"></a> </div>
        <div class="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
            <h2 class="text-2xl font-semibold leading-9 tracking-tight text-gray-900 text-center">Je me connecte
            </h2>
            <form @submit.prevent="logUser">

                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-semibold mb-2 mt-12" for="email"></label>
                    <input type="email" id="email" v-model="email" placeholder="email"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        required />
                </div>
                <div class="mb-6">
                    <label class="block text-gray-700 text-sm font-semibold mb-2" for="password"></label>
                    <div class="relative mt-2 rounded-md shadow-sm">
                        <input :type="showPassword ? 'password' : 'text'" name="password" v-model="password"
                            placeholder="*********"
                            class="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            required />
                        <div class="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
                            @click="showPassword = !showPassword" @keydown.enter="showPassword = !showPassword"
                            tabindex="0">
                            <span class="sr-only">Voir mot de passe</span>
                            <EyeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-between">
                    <button type="submit"
                        class="w-full mx-auto bg-indigo-600 hover:bg-indigo-900 text-white font-normal py-2 px-4  tracking-wide rounded-lg focus:outline-none focus:shadow-outline">
                        Me connecter
                    </button>
                </div>
                <p class="has-text-white mt-5 text-md text-indigo-700 font-medium text-right">
                    <button @click.prevent="forgotLogin">Mot de passe oublié ?</button>
                </p>
            </form>
        </div>
        <div class="max-w-md w-full bg-white p-8 rounded-lg shadow-md mt-5">
            <h2 class="text-2xl font-semibold mb-6 text-center">Je crée un compte</h2>
            <div> <button @click="displayUserCreation = true"
                    class="w-full bg-indigo-600 hover:bg-indigo-900 text-white py-2 px-4 font-normal tracking-wide rounded-lg focus:outline-none focus:shadow-outline">
                    Créer un compte
                </button></div>
        </div>
        <div v-if="errorMessage" class="mt-4 text-red-500 text-center">
            {{ errorMessage }}
        </div>
    </div>
    <UserCreation :open="displayUserCreation" @close="displayUserCreation = false" />
</template>

<script setup>
/* eslint-disable */
import { ref, inject } from 'vue';
import UserCreation from '@/components/users/UserCreation.vue';
import router from '@/router';
import { EyeIcon } from '@heroicons/vue/24/outline';

const store = inject('store');

const email = ref('');
const password = ref('');
const errorMessage = ref('');
const displayUserCreation = ref(false);
let showPassword = ref(true);

const forgotLogin = () => {
    router.push({
        name: 'ResetPassword',
    });
};

const logUser = async () => {
    try {
        await store.loginUser(email.value, password.value);
        await store.fetchUserProfiles();
        await store.setLatestProfileAsChosen(store.userProfiles);
        if (store.userProfiles.length > 0) {
            router.push({ name: 'Tableau de bord' });
        } else {
            router.push({ name: 'Créer un profil' });
        }
    } catch (error) {
        errorMessage.value = error.message;
    }
};
</script>
