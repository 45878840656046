<template>

    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-10" @close="closeDialog">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            :class="[size === 'fullscreen' ? 'w-full rounded-none h-lvh' : 'sm:my-8 sm:max-w-lg', 'relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all  sm:w-full  sm:p-6']">
                            <div class="container mx-auto max-w-5xl p-6">
                                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button type="button"
                                        class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        @click="closeDialog">
                                        <span class="sr-only">Close</span>
                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div class="sm:flex items-center mb-10">
                                    <div
                                        class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <PencilSquareIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                                    </div>
                                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                        <DialogTitle as="h3"
                                            class="text-lg font-semibold w-full leading-6 text-gray-900">
                                            Associer une structure à une zone</DialogTitle>
                                    </div>
                                </div>
                                <div v-if="typeEdition === 'modification'"> {{ objectToModify.structure.name }}</div>
                                <div>
                                    <form v-if="itemToEdit" class="w-full my-10">
                                        <Combobox as="div" v-model="selectedStructure" required
                                            @update:modelValue="query = ''">
                                            <ComboboxLabel class="block text-sm font-medium leading-6 text-gray-900">
                                                Choisissez une structure
                                            </ComboboxLabel>
                                            <div class="relative mt-2">
                                                <ComboboxInput
                                                    class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    @change="query = $event.target.value; searchStructures($event.target.value)"
                                                    @blur="query = ''"
                                                    :display-value="(structure) => structure?.name" />
                                                <ComboboxButton
                                                    class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400"
                                                        aria-hidden="true" />
                                                </ComboboxButton>

                                                <ComboboxOptions
                                                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    <ComboboxOption v-for="structure in relevantStructures"
                                                        :key="structure.id" :value="structure" as="template"
                                                        v-slot="{ active, selected }">
                                                        <li
                                                            :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                                            <span
                                                                :class="['block truncate', selected && 'font-semibold']">
                                                                {{ structure.name }}
                                                            </span>

                                                            <span v-if="selected"
                                                                :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        </li>
                                                    </ComboboxOption>
                                                </ComboboxOptions>
                                            </div>
                                        </Combobox>
                                        <div class="space-y-6 mt-10">
                                            <label for="public"
                                                class="block text-md font-medium leading-6 text-gray-900">Zones
                                            </label>
                                            <div v-for="(relevantZone) in relevantZones" :key="relevantZone.id"
                                                class="flex items-center">
                                                <input :id="`public-${relevantZone.id}`"
                                                    :name="`public-${relevantZone.id}`" :value="relevantZone"
                                                    v-model="selectedZone" type="radio"
                                                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                                <label :for="`public-${relevantZone.id}`"
                                                    class="ml-3 min-w-0 flex-1 text-gray-500">{{
        relevantZone.name }}</label>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button type="button"
                                        class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                                        @click="confirmAction"><span v-if="typeEdition === 'creation'">Créer</span><span
                                            v-else>Modifier</span></button>
                                    <button type="button"
                                        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        @click="closeDialog">Annuler</button>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { PencilSquareIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { debounce } from 'lodash';

import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/vue'
import client from '@/api/client';

const route = useRoute();
const open = ref(true);
const emit = defineEmits(['confirm', 'close', 'update:modelValue']);
const itemToEdit = defineModel();
const props = defineProps({
    typeEdition: String,
    objectToModify: [Array, Object],
    size: String,
    zones: Array,
});
const relevantZones = ref([]);
const relevantStructures = ref([]);
let selectedZone = ref(null);
let selectedStructure = ref(null);

const closeDialog = () => {
    emit('close');
};

const keywordsSelected = ref([]);

const query = ref('');

const searchStructures = debounce(async (keyword) => {
    try {
        const response = await client.get(`/api/structures/search/search-by-name?query=${keyword}`);
        relevantStructures.value = response.data;
    } catch (error) {
        console.error('Failed to fetch structures details:', error);
    }
}, 600);

const confirmAction = () => {
    const arrayToPost = ref();
    arrayToPost.value = {
        structure_id: selectedStructure.value.id,
        zone_id: selectedZone.value.id,
        event_id: route.params.id,
    };
    emit('confirm', arrayToPost);
};

onMounted(async () => {
    relevantZones.value = props.zones;

    if (props.typeEdition === 'modification') {
        selectedZone.value = props.zones.find(zone => zone.id === props.objectToModify.zone_id);
        selectedStructure.value = props.objectToModify.structure_id;
    } else {
        selectedZone.value = null;
        selectedStructure.value = null;
    }

});
watch(itemToEdit, () => {
    emit('update:modelValue', itemToEdit);
});

</script>