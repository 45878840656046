<template>
    <div
        :class="[displayedStructure.is_initial_choice ? '' : 'border border-gray-200', 'card rounded-lg flex flex-col']">
        <div :class="structureColor + ' '">
            <div class="flex items-center">
                <div>
                    <div class="card-header !py-6 px-6 !border-b-0 !pb-0">
                        <div class="flex items-center">
                            <router-link :to="'/structures/' + displayedStructure.structure.id">
                                <h2 class="text-lg font-medium">{{ displayedStructure.structure.name }}</h2>
                            </router-link>
                        </div>
                    </div>
                    <div class="card-content sm:flex items-end !pt-0 !pb-0">
                        <div class="my-auto sm:mr-3">
                            <div class="icon-line">
                                <MapPinIcon class="icon" aria-hidden="true" /><span class="icon-text">
                                    <p>{{ displayedStructure.structure.city
                                        }}</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ml-auto">
                    <div class="flex items-center">
                        <SwitchGroup as="div" class="flex items-center">
                            <Switch v-model="initial" @click="checkAsInitialChoice"
                                :class="[initial ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-indigo-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                <span aria-hidden="true"
                                    :class="[initial ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                            </Switch>
                            <SwitchLabel as="span" class="ml-3 text-sm mx-5">
                                <span class="font-medium text-gray-900" v-if="initial">Prioritaire</span>
                                <span class="font-medium text-gray-900" v-else>Optionnel</span>
                            </SwitchLabel>
                        </SwitchGroup>
                        <div class="cursor-pointer hover:fill-indigo-500">
                            <XMarkIcon aria-hidden="true" @click="$emit('delete', displayedStructure.id)"
                                class="h-8 w-8 mr-6 hover:stroke-indigo-500" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2 pt-3 px-8 pb-5 border-t border-gray-300">
                <div v-if="open" class="">
                    <label for="justification" class="font-medium text-sm mb-5">Ce qui me motive à aller dans cette
                        structure
                        c'est...</label>
                    <textarea rows="auto" v-model="justification" name="justification" id="justification"
                        @change="addJustification" autocomplete="name" required="required"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Ce qui me motive à aller dans cette structure c'est..." />

                    <div class="flex flex-wrap  mt-5"> <button class="btn-primary mr-2"
                            @click="addJustification">Enregistrer</button> <button class="btn-read"
                            @click="open = false">
                            Annuler
                        </button></div>
                </div>
                <div v-else class="flex items-center">
                    <div class="pr-5" v-if="justification">
                        <label for="justification" class="font-medium text-sm mb-5">Ce qui me motive à aller dans cette
                            structure
                            c'est...</label>
                        <div> {{ justification }}</div>
                    </div>
                    <button class="btn-read !text-small" @click="open = true" v-if="!justification">
                        <span>Explique ton choix</span>
                    </button>
                    <button class="btn-read !text-small ml-auto" @click="open = true" v-else>
                        Modifier
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { computed, inject } from 'vue';
import { defineProps } from 'vue';
import { MapPinIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
const emit = defineEmits(['update', 'delete', 'modify', 'update:modelValue']);

const initial = ref(false);
const justification = ref('');
const props = defineProps({
    displayedStructure: {
        Object,
        required: true
    },
    indexItem: {
        String,
        default: 'default'
    },
});
const colors = ['bg-cyan-100', 'bg-blue-100', 'bg-green-100', 'bg-purple-100'];
const open = ref(false);
const structureColor = computed(() => {
    return colors[Math.floor(Math.random() * colors.length)];
});

const checkAsInitialChoice = () => {
    initial.value = !initial.value;
    emit('modify', props.displayedStructure.id, initial.value, justification.value);
};

const addJustification = () => {
    console.log('justification');
    open.value = false;
    emit('modify', props.displayedStructure.id, initial.value, justification.value);
};

onMounted(() => {
    if (props.displayedStructure.is_initial_choice === 1) {
        initial.value = true;
    } else {
        initial.value = false;

    }
    justification.value = props.displayedStructure.justification;
});

</script>

<style scoped>
.selected {
    fill: #2b1382;
}
</style>
