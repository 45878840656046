<template>
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-10" @close="closeDialog">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="sm:my-8 sm:max-w-lg relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all  sm:w-full  sm:p-6'">
                            <div class="container mx-auto max-w-5xl p-6">
                                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button type="button"
                                        class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        @click="closeDialog">
                                        <span class="sr-only">Close</span>
                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div class="sm:flex items-center mb-10">
                                    <div
                                        class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <UserIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                                    </div>
                                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                        <DialogTitle as="h3"
                                            class="text-lg font-semibold w-full leading-6 text-gray-900">
                                            Créer un utilisateur</DialogTitle>

                                    </div>
                                </div>
                                <div class="mt-3 text-left sm:mt-5">
                                    <form class="">
                                        <Listbox as="div" v-model="formUser.role">
                                            <ListboxLabel class="block text-md font-medium leading-6 text-gray-900">
                                                Rôle<sup class="text-red-500 font-semibold text-base">*</sup>
                                            </ListboxLabel>
                                            <div class="relative mt-2">
                                                <ListboxButton
                                                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-md sm:leading-6">
                                                    <span class="block truncate" v-if="formUser.role">{{
        formUser.role.name
    }}</span><span v-else>Choisissez
                                                        un
                                                        rôle</span>
                                                    <span
                                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <ChevronUpDownIcon class="h-5 w-5 text-gray-400"
                                                            aria-hidden="true" />
                                                    </span>
                                                </ListboxButton>

                                                <transition leave-active-class="transition ease-in duration-100"
                                                    leave-from-class="opacity-100" leave-to-class="opacity-0">
                                                    <ListboxOptions
                                                        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-md">
                                                        <ListboxOption as="template"
                                                            v-for="availableRole in availableRoles"
                                                            :key="availableRole.id" :value="availableRole"
                                                            v-slot="{ active, selected }">
                                                            <li
                                                                :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                                <span
                                                                    :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
        availableRole.name }}</span>
                                                                <span v-if="selected"
                                                                    :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            </li>
                                                        </ListboxOption>
                                                    </ListboxOptions>
                                                </transition>
                                            </div>
                                        </Listbox>
                                        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-5">
                                            <div class="col-span-full">
                                                <label for="name"
                                                    class="block text-md font-medium leading-6 text-gray-900">Nom
                                                    complet</label>
                                                <div class="mt-2">
                                                    <input type="text" name="name" id="name" required="required"
                                                        v-model="formUser.name" placeholder="John Doe"
                                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                                </div>
                                            </div>
                                            <div class="col-span-full">
                                                <div class="">
                                                    <label for="email"
                                                        class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                                                    <input type="email" name="email" id="email" autocomplete="email"
                                                        required="required" v-model="formUser.email"
                                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                                </div>
                                            </div>

                                            <div class="col-span-full">
                                                <label for="account-number"
                                                    class="block text-sm font-medium leading-6 text-gray-900">Mot de
                                                    passe</label>
                                                <div class="relative mt-2 rounded-md shadow-sm">
                                                    <input :type="showPassword ? 'password' : 'text'" name="password"
                                                        id="password" autocomplete="password" required="required"
                                                        v-model="formUser.password"
                                                        class="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="mot de passe" />
                                                    <div class="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
                                                        @click="showPassword = !showPassword"
                                                        @keydown.enter="showPassword = !showPassword" tabindex="0">
                                                        <span class="sr-only">Voir mot de passe</span>
                                                        <EyeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-span-full">
                                                <label for="account-number"
                                                    class="block text-sm font-medium leading-6 text-gray-900">Confirmation
                                                    de Mot de
                                                    passe</label>
                                                <div class="relative mt-2 rounded-md shadow-sm">
                                                    <input :type="showPassword ? 'password' : 'text'"
                                                        name="passwordConfirmation" id="passwordConfirmation"
                                                        v-model="formUser.passwordConfirmation"
                                                        :class="[checkPassword ? 'ring-green-300' : 'ring-red-300']"
                                                        class="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="mot de passe" />
                                                    <div @click="showPassword = !showPassword"
                                                        @keydown.enter="showPassword = !showPassword" aria-hidden="true"
                                                        class="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
                                                        tabindex="0">
                                                        <EyeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    </div>
                                                </div>
                                                <p class="mt-2 text-sm text-red-600" id="email-error"
                                                    v-if="showPasswordError">Le mot de
                                                    passe est différent.</p>
                                            </div>
                                            <div class="col-span-full">
                                                <AlertMessage v-if="errorMessage" type="error" :title="errorMessage" />
                                                <AlertMessage v-if="successMessage" type="success"
                                                    :title="successMessage" />
                                            </div>
                                        </div>
                                    </form>
                                    <div class="mt-5 sm:mt-6">
                                        <button type="button" @click="createUser"
                                            class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Créer
                                            le compte</button>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, watch, onMounted } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot, DialogTitle } from '@headlessui/vue';
import { UserIcon, EyeIcon } from '@heroicons/vue/24/outline';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import AlertMessage from '@/components/alert/AlertMessage.vue';
import client from '@/api/client';
import {
    Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions
} from '@headlessui/vue';

const formUser = ref({
    name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    role: '',
});
const availableRoles = ref([]);

defineProps({
    open: Boolean,
});
const open = ref(true);
const emit = defineEmits(['close', 'confirm']);
const errorMessage = ref('');
const successMessage = ref('');

const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');

let showPassword = ref(true);
const checkPassword = computed(() => formUser.value.password === formUser.value.passwordConfirmation);

const showPasswordError = ref(false);

watch(() => formUser.value.passwordConfirmation, (newVal) => {
    showPasswordError.value = newVal.length >= formUser.value.password.length && !checkPassword.value;
});

const createUser = async () => {
    errorMessage.value = ''
    try {
        const response = await client.post('/api/create-user-profile', {
            user_name: formUser.value.name,
            user_email: formUser.value.email,
            user_password: formUser.value.password,
            role_id: formUser.value.role.id,
            genre_id: 3,
        });
        successMessage.value = 'L\'utilisateur a été créé avec succès. Il va recevoir un email de confirmation. Il devra confirmer son compte par email.';
        setTimeout(() => { emit('close'); }, 4000);
    } catch (error) {
        errorMessage.value = 'Erreur lors de la création de l\'utilisateur.' + '\n';
        if (error.response) {
            const errors = Object.entries(error.response.data.errors);
            errors.forEach((errorKey) => {
                errorMessage.value += errorKey[1] + '\n' + '\n';
            });
        }
    }
}

const fetchRoles = async () => {
    try {
        const response = await client.get('/api/roles');
        availableRoles.value = response.data;
    } catch (error) {
        errorMessage.value = 'Erreur lors de la récupération des rôles. ';
        if (error.response) {
            errorMessage.value += error.response.data.message;
        }
    }
}
const closeDialog = () => {
    emit('close');
};

onMounted(() => {
    fetchRoles();
});
</script>