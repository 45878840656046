<template>
    <AppLayout>
        <div class="mx-auto 2xl:max-w-9xl px-4 detail-page">
            <div class="hero-structures max-w-[1520px] mx-auto">
                <h1 class="title1 mb-3"> Structures sélectionnées</h1>
                <form class="w-full my-10">
                    <Combobox as="div" v-model="structureArrayToSelect" required @update:modelValue="query = ''"
                        multiple>
                        <ComboboxLabel class="block text-sm font-medium leading-6 text-gray-900">
                            Choisissez une structure
                        </ComboboxLabel>
                        <div class="relative mt-2">
                            <ComboboxInput
                                class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                @change="query = $event.target.value; searchStructures($event.target.value)"
                                @blur="query = ''" :display-value="(structure) => structure?.name" />
                            <ComboboxButton
                                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </ComboboxButton>

                            <ComboboxOptions
                                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="structure in relevantStructures" :key="structure.id"
                                    :value="structure" as="template" v-slot="{ active, selected }">
                                    <li
                                        :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ structure.name }}
                                        </span>

                                        <span v-if="selected"
                                            :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                    <div class="mt-5"></div>
                    <div v-for="(selectedStructure, index) in structureArrayToSelect" :key="selectedStructure.id">
                        <StructureCardWishlistForAdmin :displayedStructure="selectedStructure" :indexItem="index"
                            v-model="structureArrayToSelect" @update="changeModel"
                            @displayStructure="displayStructure(selectedStructure)" />
                    </div>
                    <button type="button" @click="checkWishlistsTotal"
                        class="mt-10 w-64 ml-auto inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        <span class="flex mx-auto items-center">
                            <CheckCircleIcon class="-ml-0.5 h-5 w-5 mr-3" aria-hidden="true" />
                            Ajouter un
                            vœu
                        </span>
                    </button>
                </form>
                <h2 class="mt-20 mb-10 text-2xl lg:text-3xl text-indigo-700">Vœux enregistrés pour
                    {{ eventWishlists[0]?.profile?.user.name }}
                </h2>
                <div v-if="eventWishlists">
                    <div v-for="(selectedStructure, index) in eventWishlists" :key="selectedStructure.id">
                        <StructureCardWishlistDone :displayedStructure="selectedStructure" :indexItem="index"
                            :typeCard="'list'" @modify="updateWishlistUser"
                            @delete="displayConfirmDeleteDialog = true; idTypeToEdit = selectedStructure.id;" />
                    </div>
                </div>
                <div v-if="emptySpaces > 0">
                    <span v-for="( wishlistStructure, index ) in emptySpaces" :key="index">
                        <div class="mb-5 h-[120px] flex items-center justify-center relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center
                    hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <BuildingOffice2Icon class="w-12 h-12 stroke-1 stroke-gray-300 mr-5" />
                            <span class="block text-md font-medium text-gray-900">Vœu pour rencontrer une
                                structure</span>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
            :detail="notificationMessage" @close="showNotification = false" />
        <StructureAbstract :chosenStructure="selectedStructure" v-if="displayStructureAbstract"
            :show="displayStructureAbstract" @close="displayStructureAbstract = false" />
        <SuccessDialog v-if="displaySuccesDialog" title="Bravo, tu as enregistré tous tes vœux !"
            message="Tu pourras encore modifier jusqu'à 2 jours avant l'événement" secondaryButtonLabel="Fermer"
            @closeDialog="displaySuccesDialog = false" />
        <ConfirmationDialog @confirm="deleteWishlistUser(idTypeToEdit)" v-if="displayConfirmDeleteDialog"
            :title="confirmTexts[0].title" :message="confirmTexts[0].message"
            :primaryActionLabel="confirmTexts[0].primaryActionLabel"
            :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
    </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, inject, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import AppLayout from '@/layouts/AppLayout.vue';
import fetchEventServices from '@/services/EventServices.js';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import StructureCardWishlistDone from '@/components/structures/StructureCardWishlistDone.vue';
import fetchWishlistServices from '@/services/WishlistServices.js';
import client from '@/api/client.js';
import StructureAbstract from '@/components/structures/StructureAbstract.vue';
import SuccessDialog from '@/components/dialogs/SuccessDialog.vue';
import { BuildingOffice2Icon } from '@heroicons/vue/24/outline';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import { debounce } from 'lodash';
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/vue'
import { CheckIcon, CheckCircleIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import StructureCardWishlistForAdmin from '@/components/structures/StructureCardWishlistForAdmin.vue';

const selectedStructure = ref(null);
const store = inject('store');
const route = useRoute();
const showNotification = ref(false);
const notificationType = ref('success');
const errorMessage = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');
let structureArrayToSelect = ref({ value: [] });
const nextEvent = ref(0);
const eventWishlists = ref([]);
let totalWishlists = ref(0);
const displayStructureAbstract = ref(false);
const displaySuccesDialog = ref(false);
const idTypeToEdit = ref(null);
const displayConfirmDeleteDialog = ref(false);
const relevantStructures = ref([]);
const initial = ref(false);
const chosenEvent = ref(0);
const eventType = ref(0);

let emptySpaces = ref(0);

const confirmTexts = [
    {
        title: 'Supprimer cet item ?',
        message: 'Voulez-vous supprimer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
]

const calculateEmptySpaces = () => {
    if (eventWishlists.value) {
        emptySpaces.value = 5 - eventWishlists.value.length;
    } else {
        emptySpaces.value = 5;
    }
};
calculateEmptySpaces();

const colors = ['bg-cyan-100', 'bg-blue-100', 'bg-green-100', 'bg-purple-100'];
const structureColor = computed(() => {
    return colors[Math.floor(Math.random() * colors.length)];
});

const checkAsInitialChoice = () => {
    initial.value = !initial.value;
    changeModel(initial.value);
};

const displayStructure = (structureToDisplay) => {
    displayStructureAbstract.value = true;
    selectedStructure.value = structureToDisplay;
};

const query = ref('');

const searchStructures = debounce(async (keyword) => {
    try {
        const response = await client.get(`/api/structures/search/search-by-name?query=${keyword}`);
        relevantStructures.value = response.data;
    } catch (error) {
        console.error('Failed to fetch structures details:', error);
    }
}, 600);


const changeModel = (type, value, index) => {
    structureArrayToSelect.value[index][type] = value;
    if (type === "initial" && value) {
        structureArrayToSelect.value[index].optional = false;
        structureArrayToSelect.value[index].initial = true;
    } else if (type === "optional" && value) {
        structureArrayToSelect.value[index].initial = false;
        structureArrayToSelect.value[index].optional = true;
    } else if (type === "initial" && !value) {
        structureArrayToSelect.value[index].initial = false;
        structureArrayToSelect.value[index].optional = true;
    }
};
const updateWishlistUser = async (wishlistId, initialValue, justificationValue) => {
    let initialChoice = null;
    let optionalChoice = null;
    if (initialValue) {
        initialChoice = true;
        optionalChoice = false;
    } else {
        initialChoice = false;
        optionalChoice = true;
    }
    try {
        const wishlistData = {
            is_initial_choice: initialChoice,
            is_optional_choice: optionalChoice,
            is_pre_event: eventType.value,
            justification: justificationValue,
        };
        const response = await client.put(`/api/wishlists/${wishlistId}`, wishlistData);
        const responseWishlists = await client.get(`api/wishlists-filter?profile_id=${route.params.id}&event_id=${route.params.eventId}&is_pre_event=${eventType.value}`);
        eventWishlists.value = responseWishlists.data.data;
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur : ';
        if (error.response) {
            if (error.response.data.message) { notificationTitle.value += error.response.data.message; }
            if (error.response.data.error) {
                notificationTitle.value += error.response.data.error;
            }
        }
    };
};

const deleteWishlistUser = async (whislistId) => {
    displayConfirmDeleteDialog.value = false;
    try {
        const response = await fetchWishlistServices.deleteWishlist(whislistId);
        try {
            const responseWishlists = await client.get(`api/wishlists-filter?profile_id=${route.params.id}&event_id=${route.params.eventId}&is_pre_event=${eventType.value}`);
            eventWishlists.value = responseWishlists.data.data;
        } catch (error) {
            console.error('Failed to fetch whishlists', error);
        }
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        emptySpaces.value = 5 - eventWishlists.value.length;
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    };
};

const checkWishlistsTotal = () => {
    totalWishlists.value = eventWishlists.value.length;
    if (totalWishlists.value === 0) {
        // if there are no wishlists, post all the selected structures.
        postMultiple();
    } else if (totalWishlists.value === 5) {
        // if there are 5 wishlists, show a confirmation dialog to delete some.
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur : ';
        notificationTitle.value += 'Supprime d\'abord les vœux que tu ne souhaites plus garder';
    } else if (totalWishlists.value + structureArrayToSelect.value.length < 6) {
        // if the total number of wishlists and selected structure is less than 6, post all the selected structures.
        // if there are disabled structures, check if the total number of wishlists and selected structure is less than 6.
        const disabledStructures = structureArrayToSelect.value.filter(structure => structure.disabled);
        if (disabledStructures.length > 0) {
            // if there are disabled structures, check how much
            const totalWithoutDisabled = totalWishlists.value + structureArrayToSelect.value.length - disabledStructures.length;
            if (totalWithoutDisabled < 6) {
                // if the total number of wishlists and selected structure is less than 6, post all the selected structures.
                postMultiple();
            } else {
                // if the total number of wishlists and selected structure without disabled is more than 6, show a notification.
                notificationType.value = 'error';
                showNotification.value = true;
                notificationTitle.value = 'Erreur : ';
                notificationTitle.value += 'Supprime d\'abord les vœux que tu ne souhaites plus garder';
            }
        } else {
            postMultiple();
        }

    } else if (totalWishlists.value + structureArrayToSelect.value.length > 5) {
        // if the total number of wishlists and selected structure is more than 5, show a notification.
        const disabledStructures = structureArrayToSelect.value.filter(structure => structure.disabled);
        if (disabledStructures.length > 0) {
            // if there are disabled structures, check how much
            const totalWithoutDisabled = totalWishlists.value + structureArrayToSelect.value.length - disabledStructures.length;
            if (totalWithoutDisabled < 6) {
                // if the total number of wishlists and selected structure is less than 6, post all the selected structures.
                postMultiple();

            } else {
                // esle show a notification.
                showNotification.value = true;
                notificationTitle.value = 'Erreur : ';
                notificationTitle.value += 'Supprime d\'abord les vœux que tu ne souhaites plus garder';
            }
        } else {
            postMultiple();
        }
    }
};

const postMultiple = async () => {
    const arrayToPost = ref([]);
    const wishlistData = ref();
    structureArrayToSelect.value.forEach((selectedStructure) => {
        if (!selectedStructure.disabled) {
            if (!selectedStructure.initial && !selectedStructure.optional) {
                selectedStructure.initial = false;
                selectedStructure.optional = true;
            }
            arrayToPost.value.push({
                structure_id: selectedStructure.id,
                is_initial_choice: selectedStructure.initial,
                is_optional_choice: selectedStructure.optional,
                justification: '',
            });
        }
    });
    if (totalWishlists.value > 0) {
        eventWishlists.value.forEach((wishlist) => {
            const wishlistData = {
                structure_id: wishlist.structure_id,
                is_initial_choice: wishlist.is_initial_choice,
                is_optional_choice: wishlist.is_optional_choice,
                justification: wishlist.justification,
            };
            arrayToPost.value.push(wishlistData);
        });
    }

    wishlistData.value = {
        profile_id: route.params.id,
        event_id: route.params.eventId,
        is_pre_event: eventType.value,
        wishlists: arrayToPost,
    };
    try {
        const response = await client.post('/api/multiple-wishlists', wishlistData.value);
        store.clearStructures();
        const responseWishlists = await client.get(`api/wishlists-filter?profile_id=${route.params.id}&event_id=${route.params.eventId}&is_pre_event=${eventType.value}`);
        eventWishlists.value = responseWishlists.data.data;
        structureArrayToSelect.value = [];
        if (eventWishlists.value.length === 5) {
            displaySuccesDialog.value = true;
        }
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        emptySpaces.value = 0;
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur : ';
        if (error.response) {
            if (error.response.data.message) { notificationTitle.value += error.response.data.message; }
            if (error.response.data.error) {
                notificationTitle.value += error.response.data.error;
            }
        }
    }
};

onMounted(async () => {
    store.setStructures();
    try {
        chosenEvent.value = await fetchEventServices.fetchEventById(route.params.eventId);
        if (new Date(chosenEvent.value.end_date) < new Date()) {
            eventType.value = 0;
        } else {
            eventType.value = 1;
        }
        try {
            const response = await client.get(`api/wishlists-filter?profile_id=${route.params.id}&event_id=${route.params.eventId}&is_pre_event=${eventType.value}`);
            eventWishlists.value = response.data.data;
            emptySpaces.value = 5 - eventWishlists.value.length;
        } catch (error) {
            console.error('Failed to fetch whishlists', error);
        }
    } catch (error) {
        console.error('Failed to fetch the next event:', error);
    }

    structureArrayToSelect.value = store.selectedStructures.map(structure => {
        return {
            id: structure.id,
            name: structure.name,
            disabled: false,
            initial: false,
            optional: false,
        };
    });
    const eventId = route.params.eventId;
    const userId = route.params.userId;
});
</script>