/* eslint-disable */
import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import 'core-js/stable';

const baseURL = process.env.VUE_APP_API_BASE_URL;
console.log('API Base URL:', baseURL);

const client = axios.create({
    baseURL,
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    withCredentials: true,
    timeout: 60000,
});

// Function to set the access token in cookies
function setAccessTokenCookie(value) {
    console.log("Token received: ", value);
    return new Promise((resolve) => {
        const decodedToken = jwtDecode(value);
        Cookies.set('token', value, {
            expires: new Date(decodedToken.exp * 1000),
        });
        resolve();
    });
}

// Request interceptor to add the token to headers
client.interceptors.request.use((config) => {
    const newConfig = config;
    const accessToken = Cookies.get('token');
    if (accessToken) {
        newConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return newConfig;
}, (error) => Promise.reject(error));

// Response interceptor to handle token setting
client.interceptors.response.use((response) => {
    if (response.data && Object.prototype.hasOwnProperty.call(response.data, 'token')) {
        return setAccessTokenCookie(response.data.token).then(() => response);
    }
    return response;
}, (error) => {
    console.error('API Error:', error);
    return Promise.reject(error);
});

export default client;
export { setAccessTokenCookie };
