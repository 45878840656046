/* eslint-disable */
import client from '@/api/client';

// Fetch all events
async function fetchEvents(page = 1, pageSize) {
    try {
        const response = await client.get('/api/events', {
            params: { page, per_page: pageSize },
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch events:', error);
        return error;
    }
};

// Fetch event by ID
async function fetchEventById(eventId) {
    try {
        const response = await client.get(`/api/events/${eventId}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch event with ID ${eventId}:`, error);
        return error;
    }
};

// Fetch the next event
async function fetchNextEvent() {
    try {
        const response = await client.get(`/api/next-event`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch the next event:', error);
        return error;
    }
};

// Fetch the next events
async function fetchNextEvents() {
    try {
        const response = await client.get(`/api/next-events`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch the next event:', error);
        return error;
    }
};

// Create a new event
async function createEvent(eventData) {
    try {
        const response = await client.post(`/api/events`, eventData);
        return response.data;
    } catch (error) {
        console.error('Failed to create event:', error);
        return error;
    }
};

// Update an existing event
async function updateEvent(eventId, eventData) {
    try {
        const response = await client.put(`/api/events/${eventId}`, eventData);
        return response.data;
    } catch (error) {
        console.error(`Failed to update event with ID ${eventId}:`, error);
        return error;
    }
};

// Delete an event
async function deleteEvent(eventId) {
    try {
        const response = await client.delete(`/api/events/${eventId}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to delete event with ID ${eventId}:`, error);
        return error;
    }
};

function formatDate(date) {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const formatter = new Intl.DateTimeFormat('fr-FR', { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' });
    return formatter.format(date);
}
function formatDateDay(date) {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const formatter = new Intl.DateTimeFormat('fr-FR', { day: '2-digit', month: 'long', year: 'numeric' });
    return formatter.format(date);
}

// Export all event services
const fetchEventServices = {
    fetchEvents,
    fetchEventById,
    fetchNextEvent,
    fetchNextEvents,
    createEvent,
    updateEvent,
    deleteEvent,
    formatDate,
    formatDateDay,
};

export default fetchEventServices;