<template>
    <AppLayout>
        <ProfileForm class="mb-12" :context="editMode" />
    </AppLayout>
</template>

<script setup>
import { ref } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import ProfileForm from '@/components/profiles/ProfileForm.vue';

const editMode = ref('profileCreation');
</script>
