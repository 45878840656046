<template>

    <div :class="[disabled ? 'opacity-50' : '', 'card rounded-lg flex flex-col']">
        <div class="" :class="structureColor + ' '">
            <div class="card-header !pt-6 px-6 flex flex-col">
                <div class="ml-auto">
                    <div class="-mr-2 -mt-1 flex items-center mb-2">
                        <SwitchGroup as="div" class="flex items-center">
                            <Switch v-model="initial" @click="checkAsInitialChoice"
                                :class="[initial ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-indigo-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                <span aria-hidden="true"
                                    :class="[initial ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                            </Switch>
                            <SwitchLabel as="span" class="ml-3 text-sm mx-5">
                                <span class="font-medium text-gray-900" v-if="initial">Prioritaire</span>
                                <span class="font-medium text-gray-900" v-else>Optionnel</span>
                            </SwitchLabel>
                        </SwitchGroup>
                        <EyeSlashIcon aria-hidden="true" @click="disableStructure"
                            :class="[disabled ? 'opacity-50' : '', 'h-6 w-6 mx-2']" />
                    </div>
                </div>
                <div class="flex items-center">
                    <router-link :to="'/structures/' + displayedStructure.id">
                        <h2 class="text-lg font-medium">{{ displayedStructure.name }}</h2>

                    </router-link>

                </div>
            </div>
            <div class="card-content sm:flex items-end">
                <div class="my-auto sm:mr-3">
                    <div class="icon-line">
                        <MapPinIcon class="icon" aria-hidden="true" /><span class="icon-text">
                            <p>{{ displayedStructure.city
                                }}</p>
                        </span>
                    </div>
                    <div class="icon-line">
                        <TagIcon class="icon" aria-hidden="true" /><span class="icon-text">
                            <p v-if="displayedStructure.legal_form">{{ displayedStructure.legal_form.name
                                }}</p>
                        </span>
                    </div>
                    <div class="icon-line my-2">
                        <HomeIcon class="icon" aria-hidden="true" /><span class="icon-text">
                            <div class="flex flex-wrap leading-5">
                                <p v-for="(type, index) in displayedStructure.types" :key="type.id">{{ type.name }}<span
                                        v-if="index < (displayedStructure.types.length - 1)">,&nbsp;</span></p>
                            </div>
                        </span>
                    </div>
                    <div class="icon-line ">
                        <UserIcon class="icon" aria-hidden="true" /><span class="icon-text">
                            <div class="flex flex-wrap leading-tight">
                                <p class="" v-for="(publicType, index) in displayedStructure.publics"
                                    :key="publicType.id">
                                    {{ publicType.name }}<span
                                        v-if="index < (displayedStructure.publics.length - 1)">,&nbsp;</span></p>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="ml-auto mt-5 flex flex-wrap justify-center">
                    <div class="more-icon cursor-pointer mt-2">
                        <MagnifyingGlassPlusIcon @click="$emit('displayStructure', displayedStructure)" />
                    </div>
                    <router-link class="more-icon mt-2" :to="'/structures/' + displayedStructure.id">
                        <NewspaperIcon />
                    </router-link>
                </div>

            </div>
        </div>
    </div>

</template>
<script setup>
/* eslint-disable */
import { ref } from 'vue';
import { computed, inject } from 'vue';
import { defineProps } from 'vue';
import { EyeSlashIcon, MapPinIcon, HomeIcon, TagIcon, UserIcon, StarIcon, MagnifyingGlassPlusIcon, NewspaperIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
const emit = defineEmits(['update', 'update:modelValue']);

const itemToEdit = defineModel();
const initial = ref(false);
const disabled = ref(false);
const props = defineProps({
    displayedStructure: {
        Object,
        required: true
    },
    indexItem: {
        String,
        default: 'default'
    },
});
const colors = ['bg-cyan-100', 'bg-blue-100', 'bg-green-100', 'bg-purple-100'];
const open = ref(true);
const structureColor = computed(() => {
    return colors[Math.floor(Math.random() * colors.length)];
});


const disableStructure = () => {
    disabled.value = !disabled.value;
    emit('update', 'disabled', disabled.value, props.indexItem);
};

const checkAsInitialChoice = () => {
    initial.value = !initial.value;
    emit('update', 'initial', initial.value, props.indexItem);
};
</script>

<style scoped>
.selected {
    fill: #2b1382;
}
</style>
