<template>
  <TransitionRoot as="template" :show="open">
    <Dialog class="relative z-10" @close="$emit('close')">

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div class="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                    <div class="px-4 sm:px-6">
                      <div class="flex items-start justify-between">
                        <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                          Mes structures sélectionnées
                        </DialogTitle>
                        <div class="ml-3 flex h-7 items-center">
                          <button type="button"
                            class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            @click="$emit('close')">
                            <span class="absolute -inset-2.5" />
                            <span class="sr-only">Fermer</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="relative mt-6 flex-1 px-4 sm:px-6">
                      <div v-if="structures.length > 0">
                        <div v-for="relevantStructure in structures" :key="relevantStructure.id"
                          class="break-inside-avoid">
                          <StructureCard :displayedStructure="relevantStructure.structure" :typeCard="'list'"
                            @displayStructure="displayStructure(relevantStructure.structure)" />
                        </div>
                      </div>
                      <!-- <div v-else class="rounded-md p-4 mt-4 bg-blue-100">Vous n'aviez pas sélectionné de structure à
                        rencontrer</div> -->
                      <AlertMessage type="info"
                        :title="'Désolé, il n\'y a pas de structures précédemment sélectionnées.'" class="mb-10" v-else
                        :content="'Tu n\'avais pas sélectionné de structures à rencontrer.'" />
                    </div>

                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button"
                      class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                      @click="$emit('close')">Annuler</button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import StructureCard from '@/components/structures/StructureCard.vue';
import AlertMessage from '@/components/alert/AlertMessage.vue';

const model = defineModel();
const emit = defineEmits(['update:modelValue', 'close', 'update']);

const props = defineProps({
  structures: Array,
});
const open = ref(true);

onMounted(() => {

});
</script>
