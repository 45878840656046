<template>
    <div>
        <div v-if="trainingSituationItem && editMode === 'read'">
            <div class="card border-2 border-gray-900/5 rounded-lg p-3 mb-4">

                <div class="flex justify-between items-center">
                    <div class="w-1/3"><img
                            :src="'https://apifesttest.trajectoire-formation.com/storage/' + trainingSituationItem.situation_photo"
                            alt="" class="object-cover w-full rounded-lg" v-if="trainingSituationItem.situation_photo">
                        <div v-else class="bg-gray-100 w-full min-h-32 h-full flex rounded-lg">
                            <PhotoIcon class="h-12 w-12 place-content-center m-auto text-gray-300" />
                        </div>
                    </div>
                    <div class="w-2/3 ml-5">
                        <p class="font-medium">{{ trainingSituationItem.name }}</p>
                        <p class="mb-2">{{ trainingSituationItem.description }}</p>
                        <button class="rounded-xl border border-gray-300 px-2 text-sm mt-auto"
                            @click="modifySituation()">Modifier la situation</button>
                    </div>
                </div>
            </div>
        </div>
        <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            v-if="editMode === 'edition' || !trainingSituationItem">
            <div class="px-4 py-6 sm:p-8">
                <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="col-span-full">
                        <label for="name" class="block text-md font-medium leading-6 text-gray-900">Nom<sup
                                class="text-red-500 font-semibold text-base">*</sup></label>
                        <div class="mt-2">
                            <input type="text" name="training_situation_name" id="training_situation_name"
                                autocomplete="training_situation_name" v-model="formTrainingSituation.name"
                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                        </div>
                    </div>
                    <div class="col-span-full">
                        <label for="street-address"
                            class="block text-md font-medium leading-6 text-gray-900">Présentation</label>
                        <div class="mt-2">
                            <textarea rows="4" name="training_situation_description" id="training_situation_description"
                                v-model="formTrainingSituation.description"
                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                        </div>
                    </div>
                    <div class="col-span-full">
                        <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-900">Cover
                            photo</label>
                        <div
                            class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                            <div class="text-center">
                                <PhotoIcon class="w-12 h-12 stroke-1 stroke-gray-300 mx-auto mb-5" aria-hidden="true"
                                    v-if="!trainingSituationItem || !trainingSituationItem.situation_photo" />
                                <img v-else
                                    :src="'https://apifesttest.trajectoire-formation.com/storage/' + trainingSituationItem.situation_photo"
                                    alt="" class="object-cover w-full rounded-lg w-1/2 mx-auto">
                                <div>
                                </div>

                                <div class="mt-4 flex text-sm leading-6 text-gray-600">
                                    <label for="file-upload"
                                        class="mx-auto relative cursor-pointer rounded-md bg-white font-normal text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                                        <input type="file" @change="selectSituationFile($event)" class="file-btn" />
                                    </label>

                                </div>
                                <p class=" text-normal leading-5 text-gray-600">jpeg, png, jpg, gif, svg,
                                    maximum
                                    2048 px</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8"
                v-if="structureId">
                <button type="button" class="text-md font-semibold leading-6 text-gray-900"
                    @click="editMode = 'read'; $emit('update')">
                    Annuler
                </button>
                <button v-if="!trainingSituationItem" class="rounded-md bg-indigo-600 px-3 py-2 text-md font-semibold text-white shadow-sm
                                hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    @click.prevent="createTrainingSituation">
                    Créer
                </button>
                <button v-else class="rounded-md bg-indigo-600 px-3 py-2 text-md font-semibold text-white shadow-sm
                                hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    @click.prevent="updateTrainingSituation">
                    modifier la situation
                </button>
            </div>
        </form>
        <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
            :detail="notificationMessage" @close="showNotification = false" />
    </div>
</template>
<script setup>
/* eslint-disable */
import { onMounted, ref, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { defineProps } from 'vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import { PhotoIcon } from '@heroicons/vue/24/outline';
import client from '@/api/client';
const route = useRoute();

const emit = defineEmits(['update']);

const props = defineProps({
    trainingSituationItem: {
        Array
    },
    attachedStructure: {
        Number
    },
    editMode: {
        String
    },
});
const store = inject('store');

const showNotification = ref(false);
const notificationType = ref('success');
const errorMessage = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');
const editMode = ref('read');
let selectedSituationPhoto = ref(null);
let formTrainingSituation = ref([{
}]);
let structureId = ref(route.params.id);

const createTrainingSituation = async () => {
    console.log('formTrainingSituation.name', formTrainingSituation.value.name);
    console.log('formTrainingSituation.description', formTrainingSituation.value.description);
    const idStructures = Number(structureId.value);
    console.log(idStructures);
    const formDataSituation = new FormData();
    formDataSituation.append('name', formTrainingSituation.value.name);
    formDataSituation.append('description', formTrainingSituation.value.description);
    formDataSituation.append('situation_photo', selectedSituationPhoto);
    formDataSituation.append('structures[]', idStructures);

    try {
        const response = await client.post(`/api/training-situations`, formDataSituation, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
                _method: "POST",
            },
        });
        emit('update', response.data);
        editMode.value = 'read';
        console.log('response', response.data);
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, la situation a bien été modifiée';
        notificationMessage.value = 'Vous pouvez encore la modifier.';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre situation';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
}

const updateTrainingSituation = async () => {
    const idTrainingSituation = Number(props.trainingSituationItem.id);
    const formDataSituation = new FormData();
    formDataSituation.append('name', formTrainingSituation.name);
    formDataSituation.append('description', formTrainingSituation.description);
    formDataSituation.append('situation_photo', selectedSituationPhoto);

    try {
        console.log('formDataSituation', formDataSituation, idTrainingSituation);
        const response = await client.post(`/api/training-situations/${idTrainingSituation}`, formDataSituation, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
                _method: "PUT",
            },
        });
        editMode.value = 'read';
        emit('update', response.data);
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, la situation a bien été modifiée';
        notificationMessage.value = 'Vous pouvez encore la modifier.';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre situation';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
}

const modifySituation = () => {
    editMode.value = 'edition';
}

const selectSituationFile = (event) => {
    const file = event.target.files[0];
    if (file) {
        selectedSituationPhoto = file;
    }
}

onMounted(() => {
    if (props.trainingSituationItem) {
        formTrainingSituation = props.trainingSituationItem;
    }
});
</script>
