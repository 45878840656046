<template>

    <div :class="[disabled ? 'opacity-50' : '', 'card rounded-lg flex flex-col']">
        <div class="" :class="structureColor + ' '">
            <div class="card-header !pt-6 px-6">
                <div class="flex items-center">
                    <router-link :to="'/structures/' + displayedStructure.id">
                        <h2 class="text-lg font-medium">{{ displayedStructure.name }}</h2>

                    </router-link>

                    <div class="ml-auto flex items-center">
                        <SwitchGroup as="div" class="flex items-center">
                            <Switch v-model="initial" @click="checkAsInitialChoice"
                                :class="[initial ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-indigo-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                <span aria-hidden="true"
                                    :class="[initial ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                            </Switch>
                            <SwitchLabel as="span" class="ml-3 text-sm mx-5">
                                <span class="font-medium text-gray-900" v-if="initial">Prioritaire</span>
                                <span class="font-medium text-gray-900" v-else>Optionnel</span>
                            </SwitchLabel>
                        </SwitchGroup>
                        <EyeSlashIcon aria-hidden="true" @click="disableStructure"
                            :class="[disabled ? 'opacity-50' : '', 'h-5 w-5 mx-5']" />
                        <!-- <button class="btn-primary !bg-emerald-400">valider</button> -->
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>
<script setup>
/* eslint-disable */
import { ref } from 'vue';
import { computed, inject } from 'vue';
import { defineProps } from 'vue';
import { EyeSlashIcon, MapPinIcon, HomeIcon, TagIcon, UserIcon, StarIcon, MagnifyingGlassPlusIcon, NewspaperIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
const emit = defineEmits(['update', 'update:modelValue']);

const itemToEdit = defineModel();
const initial = ref(false);
const disabled = ref(false);
const props = defineProps({
    displayedStructure: {
        Object,
        required: true
    },
    indexItem: {
        String,
        default: 'default'
    },
});
const colors = ['bg-cyan-100', 'bg-blue-100', 'bg-green-100', 'bg-purple-100'];
const open = ref(true);
const structureColor = computed(() => {
    return colors[Math.floor(Math.random() * colors.length)];
});


const disableStructure = () => {
    disabled.value = !disabled.value;
    emit('update', 'disabled', disabled.value, props.indexItem);
};

const checkAsInitialChoice = () => {
    console.log('checkAsInitialChoice');
    initial.value = !initial.value;
    emit('update', 'initial', initial.value, props.indexItem);
};
</script>

<style scoped>
.selected {
    fill: #2b1382;
}
</style>
