<template>
    <div>
        <TransitionRoot as="template" :show="open">
            <Dialog class="relative z-10" @close="closeDialog">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0"
                    enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild as="template" enter="ease-out duration-300"
                            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                            leave-from="opacity-100 translate-y-0 sm:scale-100"
                            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <DialogPanel
                                :class="[size === 'fullscreen' ? 'w-full rounded-none' : 'sm:my-8 sm:max-w-lg', 'relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all  sm:w-full  sm:p-6']">
                                <div class="container mx-auto max-w-5xl p-6">
                                    <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button type="button"
                                            class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            @click="closeDialog">
                                            <span class="sr-only">Close</span>
                                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div class="sm:flex items-center mb-10">
                                        <div
                                            class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <PencilSquareIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                            <DialogTitle as="h3"
                                                class="text-lg font-semibold w-full leading-6 text-gray-900">
                                                {{ title }}</DialogTitle>

                                        </div>
                                    </div>
                                    <div>
                                        <form v-if="itemToEdit" class="w-full my-10">
                                            <div class=" w-full">
                                                <div class="mt-5">
                                                    <label for="degree_id"
                                                        class="block text-md font-medium leading-6 text-gray-900">Diplôme</label>
                                                    <Listbox as="div" v-model="trainingActionDegree">
                                                        <ListboxLabel
                                                            class="block text-md font-medium leading-6 text-gray-900">

                                                        </ListboxLabel>
                                                        <div class="relative mt-2">
                                                            <ListboxButton
                                                                class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-md sm:leading-6">
                                                                <span class="block truncate"
                                                                    v-if="trainingActionDegree">{{
            trainingActionDegree.name
        }}</span><span v-else>Choisissez
                                                                    un
                                                                    diplôme</span>
                                                                <span
                                                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400"
                                                                        aria-hidden="true" />
                                                                </span>
                                                            </ListboxButton>

                                                            <transition
                                                                leave-active-class="transition ease-in duration-100"
                                                                leave-from-class="opacity-100"
                                                                leave-to-class="opacity-0">
                                                                <ListboxOptions
                                                                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-md">
                                                                    <ListboxOption as="template"
                                                                        v-for="relevantDegree in relevantDegrees"
                                                                        :key="relevantDegree.id" :value="relevantDegree"
                                                                        v-slot="{ active, selected }">
                                                                        <li
                                                                            :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                                            <span
                                                                                :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
            relevantDegree.name }}</span>
                                                                            <span v-if="selected"
                                                                                :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                                                <CheckIcon class="h-5 w-5"
                                                                                    aria-hidden="true" />
                                                                            </span>
                                                                        </li>
                                                                    </ListboxOption>
                                                                </ListboxOptions>
                                                            </transition>
                                                        </div>
                                                    </Listbox>
                                                </div>
                                                <div class="mt-5">
                                                    <label for="degree_id"
                                                        class="block text-md font-medium leading-6 text-gray-900">Événement</label>
                                                    <span
                                                        class="my-3 bg-indigo-100 text-indigo-900 inline-flex items-center gap-x-1.5 rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium">
                                                        {{
            objectToModify.event?.name }}</span>
                                                    <Listbox as="div" v-model="trainingActionEvent">
                                                        <ListboxLabel
                                                            class="block text-md font-medium leading-6 text-gray-900">

                                                        </ListboxLabel>
                                                        <div class="relative mt-2">
                                                            <ListboxButton
                                                                class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-md sm:leading-6">
                                                                <span class="block truncate"
                                                                    v-if="trainingActionEvent">{{
            trainingActionEvent.name
        }}</span><span v-else>Choisissez
                                                                    un
                                                                    événement</span>
                                                                <span
                                                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400"
                                                                        aria-hidden="true" />
                                                                </span>
                                                            </ListboxButton>

                                                            <transition
                                                                leave-active-class="transition ease-in duration-100"
                                                                leave-from-class="opacity-100"
                                                                leave-to-class="opacity-0">
                                                                <ListboxOptions
                                                                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-md">
                                                                    <ListboxOption as="template"
                                                                        v-for="relevantEvent in relevantEvents"
                                                                        :key="relevantEvent.id" :value="relevantEvent"
                                                                        v-slot="{ active, selected }">
                                                                        <li
                                                                            :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                                            <span
                                                                                :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
            relevantEvent.name }}</span>
                                                                            <span v-if="selected"
                                                                                :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                                                <CheckIcon class="h-5 w-5"
                                                                                    aria-hidden="true" />
                                                                            </span>
                                                                        </li>
                                                                    </ListboxOption>
                                                                </ListboxOptions>
                                                            </transition>
                                                        </div>
                                                    </Listbox>
                                                </div>
                                                <div class="mt-5">
                                                    <label for="year"
                                                        class="block text-md font-medium leading-6 text-gray-900">Année</label>
                                                    <div class="mt-2">
                                                        <input type="text" name="year" id="year" autocomplete="name"
                                                            required="required" v-model="formTrainingAction.year"
                                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                                    </div>
                                                </div>

                                                <div class="mt-5">
                                                    <label
                                                        class="block text-md font-medium leading-6 text-gray-900">Date
                                                        de début</label>
                                                    <div class="mt-2">
                                                        <input type="datetime-local" name="start_date" id="start_date"
                                                            autocomplete="name" required="required"
                                                            v-model="formTrainingAction.start_date"
                                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                                    </div>
                                                </div>
                                                <div class="mt-5">
                                                    <label
                                                        class="block text-md font-medium leading-6 text-gray-900">Date
                                                        de fin</label>
                                                    <div class="mt-2">
                                                        <input type="datetime-local" name="end_date" id="end_date"
                                                            autocomplete="name" required="required"
                                                            v-model="formTrainingAction.end_date"
                                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                                    </div>
                                                </div>

                                            </div>

                                        </form>
                                        <div>
                                            <ProfilesRelatedToTrainigAction v-if="profilesRelated?.length > 0"
                                                class="mb-10" :profiles="profilesRelated"
                                                :idTrainingAction="trainingActionId"
                                                @confirm="refreshProfilesRelated" />
                                        </div>
                                        <button class="btn-primary mt-10"
                                            @click.prevent="displayAddRelatedProfiles = !displayAddRelatedProfiles ">Ajouter
                                            des profils</button>
                                        <AddProfilesRelated v-if="displayAddRelatedProfiles"
                                            @close="displayAddRelatedProfiles = false" @confirm="refreshProfilesRelated"
                                            :idTrainingAction="objectToModify.id" />
                                    </div>
                                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <button type="button"
                                            class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                            @click="editItem"><span v-if="typeEdition==='creation'">Créer</span><span
                                                v-else>Modifier</span></button>
                                        <button type="button"
                                            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            @click="closeDialog">Annuler</button>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
        <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
            :detail="notificationMessage" @close="showNotification = false" />
    </div>
</template>

<script setup>
/* eslint-disable */
import client from '@/api/client';
import { ref, defineProps, reactive, onMounted } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { PencilSquareIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { CheckIcon, ChevronUpDownIcon, XCircleIcon } from '@heroicons/vue/20/solid';
import {
    Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions
} from '@headlessui/vue';
import fetchServices from '@/services/fetchServices.js';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import fetchEventServices from '@/services/EventServices.js';
import AddProfilesRelated from '@/components/training-actions/AddProfilesRelated.vue';
import ProfilesRelatedToTrainigAction from '@/components/training-actions/ProfilesRelatedToTrainigAction.vue';

const open = ref(true);
const emit = defineEmits(['confirm', 'close', 'update:modelValue']);
const itemToEdit = defineModel();
const props = defineProps({
    typeEdition: String,
    title: String,
    objectToModify: [Array, Object],
    size: String
});

const showNotification = ref(false);
const notificationType = ref('success');
const errorMessage = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');

const relevantDegrees = ref([]);
const relevantEvents = ref([]);
const trainingActionDegree = ref();
const trainingActionEvent = ref();
const formTrainingAction = ref([]);
const displayAddRelatedProfiles = ref(false);
const profilesRelated = ref([]);
const trainingActionId = ref(null);

const closeDialog = () => {
    emit('close');
};

const editItem = () => {
    if (props.typeEdition === 'creation') {
        createItem();
    } else {
        modifyItem();
    }
}

const modifyItem = async () => {
    try {
        const response = await client.put(`/api/training-actions/${props.objectToModify.id}`, {
            start_date: formTrainingAction.value.start_date,
            end_date: formTrainingAction.value.end_date,
            year: formTrainingAction.value.year,
            degree_id: trainingActionDegree.value.id,
            event_id: trainingActionEvent.value.id
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        emit('confirm');
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre action';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    };
}

const createItem = async () => {
    console.log('createItem');
    try {
        const response = await client.post('/api/training-actions', {
            start_date: formTrainingAction.value.start_date,
            end_date: formTrainingAction.value.end_date,
            year: formTrainingAction.value.year,
            degree_id: trainingActionDegree.value.id,
            event_id: trainingActionEvent.value.id
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été créé';
        notificationMessage.value = '';
        emit('confirm');
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre action';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
};
const refreshProfilesRelated = async () => {
    try {
        const response = await client.get(`/api/training-actions/${props.objectToModify.id}`);
        profilesRelated.value = response.data.profiles;
    } catch (error) {
        console.error('Failed to fetch training actions by id', error);
    }
};
onMounted(async () => {
    try {
        relevantDegrees.value = await fetchServices.fetchDegreesPublics();
        relevantEvents.value = await fetchEventServices.fetchNextEvents();
    } catch (error) {
        console.error('Failed to fetch degrees', error);
    }

    if (props.typeEdition === 'modification') {
        formTrainingAction.value.start_date = new Date(props.objectToModify.start_date).toISOString().slice(0, 16);
        formTrainingAction.value.end_date = new Date(props.objectToModify.end_date).toISOString().slice(0, 16);
        formTrainingAction.value.year = props.objectToModify.year;
        trainingActionDegree.value = relevantDegrees.value.find(relevantDegree => relevantDegree.id === props.objectToModify.degree.id);
        trainingActionEvent.value = relevantEvents.value.find(relevantEvent => relevantEvent.id === props.objectToModify.event.id);
        profilesRelated.value = props.objectToModify.profiles;
        trainingActionId.value = props.objectToModify.id;
    }

});

</script>