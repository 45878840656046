<template>

    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-10" @close="closeDialog">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            :class="[size === 'fullscreen' ? 'w-full rounded-none' : 'sm:my-8 sm:max-w-lg', 'relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all  sm:w-full  sm:p-6']">
                            <div class="container mx-auto max-w-5xl p-6">
                                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button type="button"
                                        class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        @click="closeDialog">
                                        <span class="sr-only">Close</span>
                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div class="sm:flex items-center mb-10">
                                    <div
                                        class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <PencilSquareIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                                    </div>
                                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                        <DialogTitle as="h3"
                                            class="text-lg font-semibold w-full leading-6 text-gray-900">
                                            {{ title }}</DialogTitle>

                                    </div>
                                </div>
                                <div>
                                    <form v-if="itemToEdit" class="w-full my-10">
                                        <div class=" w-full" v-for="(inputField, index) in itemToEdit"
                                            :key="inputField.field">
                                            <div v-if="inputField.type === 'text'" class="mt-5">
                                                <label :for="inputField.field"
                                                    class="block text-md font-medium leading-6 text-gray-900">{{
        inputField.label }}</label>
                                                <div class="mt-2">
                                                    <input :type="inputField.type" :name="inputField.field"
                                                        :id="inputField.field" autocomplete="name" required="required"
                                                        v-model="itemToEdit[index].entry"
                                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                                </div>
                                            </div>
                                            <div v-if="inputField.type === 'textarea'" class="mt-5">
                                                <label :for="inputField.field"
                                                    class="block text-md font-medium leading-6 text-gray-900">{{
        inputField.label }}</label>
                                                <div class="mt-2">
                                                    <textarea rows="5" v-model="itemToEdit[index].entry"
                                                        :name="inputField.field" :id="inputField.field"
                                                        autocomplete="name" required="required"
                                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="Add your comment..." />
                                                </div>
                                            </div>
                                            <div v-if="inputField.type === 'datetime-local' || inputField.type === 'time'"
                                                class="mt-5">
                                                <label :for="inputField.field"
                                                    class="block text-md font-medium leading-6 text-gray-900">{{
        inputField.label }}</label>
                                                <div class="mt-2">
                                                    <input :type="inputField.type" :name="inputField.field"
                                                        :id="inputField.field" autocomplete="name" required="required"
                                                        v-model="itemToEdit[index].entry"
                                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                                </div>
                                            </div>
                                            <SwitchGroup as="div" class="flex items-center mt-10"
                                                v-if="inputField.type === 'boolean'">
                                                <Switch v-model="itemToEdit[index].entry" value="true"
                                                    :class="[itemToEdit[index].entry ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                                    <span aria-hidden="true"
                                                        :class="[itemToEdit[index].entry ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                                                </Switch>
                                                <SwitchLabel as="span" class="ml-3 text-sm">
                                                    <span class="font-medium text-gray-900"
                                                        v-if="itemToEdit[index].entry === true">Actif</span>
                                                    <span class="font-medium text-gray-900" v-else>Inactif</span>

                                                </SwitchLabel>
                                            </SwitchGroup>
                                        </div>

                                    </form>
                                </div>
                                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button type="button"
                                        class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                                        @click="confirmAction"><span v-if="typeEdition==='creation'">Créer</span><span
                                            v-else>Modifier</span></button>
                                    <button type="button"
                                        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        @click="closeDialog">Annuler</button>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, reactive, onMounted, watch } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { PencilSquareIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';

const open = ref(true);
const emit = defineEmits(['confirm', 'close', 'update:modelValue']);

const itemToEdit = defineModel();
const props = defineProps({
    typeEdition: String,
    title: String,
    objectToModify: [Array, Object],
    size: String,
});
const enabled = ref(false);

const closeDialog = () => {
    emit('close');
};

const confirmAction = () => {
    emit('confirm', itemToEdit);
};
onMounted(() => {
    console.log('mounted', itemToEdit.value);
    if (props.typeEdition === 'modification') {
        itemToEdit.value.forEach((item) => {
            if (item.field in props.objectToModify) {
                if (item.type === 'boolean' && props.objectToModify[item.field] === 1) {
                    item.entry = true;
                } else if (item.type === 'boolean' && props.objectToModify[item.field] === 0) {
                    item.entry = false;
                } else if (item.type === 'boolean' && !props.objectToModify) {
                    console.log('null');
                    item.entry = true;
                }
                else if (item.type === 'date') {
                    const date = new Date(props.objectToModify[item.field]);
                    item.entry = date;
                } else {
                    item.entry = props.objectToModify[item.field];
                }
            }
        });
    } else {
        console.log('itemToEditmoound dialog', itemToEdit.value);
    }
});

watch(itemToEdit, () => {
    emit('update:modelValue', itemToEdit);
});
</script>