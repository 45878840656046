<template>
    <AppLayout>
        <div class="md:flex md:items-center md:justify-between px-6 sm:px-10">
            <div class="min-w-0 flex-1">
                <div class="hero-structures max-w-[1520px] mx-auto">
                    <h1 class="title1 text-5xl mb-5 !mt-20"><span>Action de formation</span>
                    </h1>
                    <h2 class="text-2xl text-indigo-600 mb-10">
                        {{ relevantTrainingAction?.degree?.name }}
                        <p>{{ relevantTrainingAction?.event?.name }}</p>
                        <p>{{ relevantTrainingAction?.year }}</p>
                    </h2>
                    {{ relevantTrainingAction.start_date }} - {{ relevantTrainingAction.end_date }}
                </div>
            </div>
        </div>
        <div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-[1520px] bg-white pt-8">
            <nav aria-label="Progress" class="mb-20">
                <ol role="list" class="space-y-4 md:flex md:space-x-8 md:space-y-0">
                    <li v-for="step in steps" :key="step.name" class="md:flex-1">
                        <a v-if="step.status === 'complete'" :href="step.href"
                            class="group flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-b-4 md:pb-0 md:pl-0 md:pb-4">
                            <span class="text-sm font-medium text-indigo-600 group-hover:text-indigo-800">{{ step.id
                                }}</span>
                            <span class="text-sm font-medium">{{ step.name }}</span>
                        </a>
                        <a v-else-if="step.status === 'current'" :href="step.href"
                            class="flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-b-4 md:pb-0 md:pl-0 md:pb-4"
                            aria-current="step">
                            <span class="text-sm font-medium text-indigo-600">{{ step.id }}</span>
                            <span class="text-sm font-medium">{{ step.name }}</span>
                        </a>
                        <a v-else :href="step.href"
                            class="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-b-4 md:pb-0 md:pl-0 md:pb-4">
                            <span class="text-sm font-medium text-gray-500 group-hover:text-gray-700">{{ step.id
                                }}</span>
                            <span class="text-sm font-medium">{{ step.name }}</span>
                        </a>
                    </li>
                </ol>
            </nav>
            <h2 class="text-base font-semibold leading-6 text-gray-900">Profils associés</h2>
            <p class="mt-2 text-sm text-gray-700 mb-5">Liste des profils associés</p>
            <section id="profiles">
                <ul role="list"
                    class="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                    <li v-for="person in profilesRelated" :key="person.email" class="mb-3">
                        <div class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 mb-3">
                            <div class="flex min-w-0 gap-x-4">
                                <img class="h-12 w-12 flex-none rounded-full bg-gray-50"
                                    :src="person.profile_picture_url" alt="" v-if="person?.profile_picture_url" />
                                <UserCircleIcon class="h-12 w-12 text-gray-300 -ml-1 flex-none rounded-full"
                                    aria-hidden="true" v-else />
                                <div class="min-w-0 flex-auto">
                                    <p class="text-sm font-semibold leading-6 text-gray-900">
                                        {{ person.user_name }}
                                    </p>
                                    <p class="mt-1 flex text-xs leading-5 text-gray-500">
                                        {{ person.role_name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </section>
            <section id="structures" class="mt-10">
                <h2 class="text-base font-semibold leading-6 text-gray-900">Structures associées</h2>
                <p class="mt-2 text-sm text-gray-700 mb-5 ">Liste des profils associés</p>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div v-for=" selectedStructure  in relevantTrainingAction.structures" :key="selectedStructure.id">
                        <StructureCard :displayedStructure="selectedStructure" :typeCard="'list'"
                            @displayStructure="displayStructure(selectedStructure)" />
                    </div>
                </div>
            </section>
        </div>
    </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import AppLayout from '@/layouts/AppLayout.vue';
import client from '@/api/client';
import { UserCircleIcon } from '@heroicons/vue/24/solid';
import StructureCard from '@/components/structures/StructureCard.vue';

const steps = [
    { id: 'Profils', name: 'Profils associés', href: '#profiles', status: 'upcoming', title: 'Informations générales sur la structure', description: 'Renseignez ici les informations générales de localisation et contact sur la structure' },
    { id: 'Structures', name: 'Structures associées', href: '#structures', status: 'upcoming', title: 'Présentation de la structure', description: 'Présentez ici de la structure, ses activités, ses publics, ses spécificités, etc.' },
]
const route = useRoute();
const relevantTrainingAction = ref([]);
const trainingActionId = ref(route.params.id);
const startDate = ref(null);
const endDate = ref(null);
let profilesRelated = ref([]);

onMounted(async () => {
    try {
        const response = await client.get(`/api/training-actions/${trainingActionId.value}`);
        relevantTrainingAction.value = response.data;
        profilesRelated.value = response.data.profiles;
    } catch (error) {
        console.error('Failed to fetch training actions by id', error);
    }
});

</script>
