<template>

  <div class="min-h-screen flex items-center flex-col justify-center bg-gray-100">
    <div class="mb-10"><a href="/" alt="logo Trajectoire fest"><img class="h-12 sm:h-16"
          src="@/assets/img/logos/logo-trajectoire.svg" alt="Trajecoire Fest"></a> </div>
    <div class="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
      <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 mb-3">
        <UserIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
      <h2 class="mb-10 text-xl font-semibold leading-9 tracking-tight text-gray-900 text-center">Vous avez oublié votre
        mot de
        passe
      </h2>
      Rien de grave, entrez votre identifiant ou adresse email pour le
      réinitialiser

      <AlertMessage v-if="errorMessage" type="error" :title="errorMessage"
        :content="'Nous ne le trouvons pas dans notre base...'" />
      <AlertMessage v-if="passwordreset === true" type="success"
        :title="'Vous allez recevoir, par email, un lien pour réinitialiser votre mot de passe.'" />
      <form>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-semibold mb-2 mt-12" for="email"></label>
          <input type="email" id="email" v-model="form.email" placeholder="email"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
            required />
        </div>

        <div class="flex items-center justify-between">
          <button type="submit" @click.prevent="resetPassword" class="w-full mx-auto bg-indigo-600 hover:bg-indigo-900 text-white font-normal py-2 px-4 tracking-wide
            rounded-lg focus:outline-none focus:shadow-outline">
            Régénérer<i class="fa fa-sign-in" aria-hidden="true"></i>
          </button>
        </div>

      </form>
    </div>

  </div>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import client from '@/api/client';
import { UserIcon, EyeIcon } from '@heroicons/vue/24/outline';
import AlertMessage from '@/components/alert/AlertMessage.vue';

const errored = ref(false);
const errorMessage = ref('');
const invalidemail = ref(false);
const passwordreset = ref(false);
const form = ref({
  email: null,
});

const resetPassword = async () => {
  try {
    const response = await client.post('/api/password-reset', form.value);
    if (response.status === 200) {
      passwordreset.value = true;
    }
  } catch (err) {
    if (err.response.status === 404) {
      invalidemail.value = true;
    } else {
      errorMessage.value = err.response.data.message || 'Êtes-vous sûr·e de votre adresse email ?';
    }
  }
};

</script>
