<template>
    <div>
        <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
            :detail="notificationMessage" @close="showNotification = false" />
        <ConfirmationDialog @confirm="deleteItem(idTypeToEdit)" v-if="displayConfirmDeleteDialog"
            :title="confirmTexts[0].title" :message="confirmTexts[0].message"
            :primaryActionLabel="confirmTexts[0].primaryActionLabel"
            :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
        <ul role="list"
            class="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
            <li v-for="person in profiles" :key="person.email">
                <div class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
                    <div class="flex min-w-0 gap-x-4">
                        <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="person.profile_picture_url"
                            alt="" v-if="person.profile_picture_url" />
                        <UserCircleIcon class="h-12 w-12 text-gray-300 -ml-1 flex-none rounded-full" aria-hidden="true"
                            v-else />
                        <div class="min-w-0 flex-auto">
                            <p class="text-sm font-semibold leading-6 text-gray-900">
                                {{ person.user_name }}
                                <!-- Todo: check values when endpoint updated-->
                            </p>
                            <span :class="'bg-role' + person.role.id"
                                class="mr-2 inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700">

                                {{ person.role_name }}
                            </span>
                        </div>
                    </div>
                    <div class="flex shrink-0 items-center gap-x-4">
                        <XMarkIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true"
                            @click.prevent="displayConfirmDeleteDialog = true; typeEdition = 'creation'; idTypeToEdit = person.id" />
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import { UserCircleIcon, XMarkIcon } from '@heroicons/vue/24/solid';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';

const displayConfirmDeleteDialog = ref(false);
const props = defineProps({
    profiles: [Array, Object],
    idTrainingAction: [Number, String],
});

const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const idTypeToEdit = ref(null);
const confirmTexts = [
    {
        title: 'Supprimer cet item ?',
        message: 'Voulez-vous supprimer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
]
const emit = defineEmits(['confirm']);

const deleteItem = async (id) => {
    displayConfirmDeleteDialog.value = false;
    try {
        await client.delete(`/api/training-actions/${props.idTrainingAction}/remove-profile`, {
            data: {
                profile_id: id,
            },
        });
        emit('confirm');
    } catch (error) {
        console.error('Failed to delete profile:', error);
    }
};
</script>