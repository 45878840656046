<template>
    <div class="">
        <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
            :detail="notificationMessage" @close="showNotification = false; errorMessage = '';" />
        <div class="md:flex md:items-center md:justify-between px-6 sm:px-10">
            <div class="min-w-0 flex-1">
                <div class="hero-structures max-w-[1520px] mx-auto">
                    <h1 class="title1 text-5xl mb-5 !mt-20"><span
                            v-if="context === 'structureCreation'">Créer</span><span v-else>Modifer</span>
                        une structure</h1>
                    <div v-if="formStructure.name" class="text-2xl text-indigo-600 mb-10">{{ formStructure.name }}</div>
                </div>

            </div>
        </div>

        <div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-[1520px] bg-white pt-8">
            <nav aria-label="Progress" class="mb-20">
                <ol role="list" class="space-y-4 md:flex md:space-x-8 md:space-y-0">
                    <li v-for="step in steps" :key="step.name" class="md:flex-1">
                        <a v-if="step.status === 'complete'" :href="step.href"
                            class="group flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-b-4 md:pb-0 md:pl-0 md:pb-4">
                            <span class="text-sm font-medium text-indigo-600 group-hover:text-indigo-800">{{ step.id
                                }}</span>
                            <span class="text-sm font-medium">{{ step.name }}</span>
                        </a>
                        <a v-else-if="step.status === 'current'" :href="step.href"
                            class="flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-b-4 md:pb-0 md:pl-0 md:pb-4"
                            aria-current="step">
                            <span class="text-sm font-medium text-indigo-600">{{ step.id }}</span>
                            <span class="text-sm font-medium">{{ step.name }}</span>
                        </a>
                        <a v-else :href="step.href"
                            class="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-b-4 md:pb-0 md:pl-0 md:pb-4">
                            <span class="text-sm font-medium text-gray-500 group-hover:text-gray-700">{{ step.id
                                }}</span>
                            <span class="text-sm font-medium">{{ step.name }}</span>
                        </a>
                    </li>
                </ol>
            </nav>
            <div class="space-y-10 divide-y divide-gray-900/10">
                <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                    <div class="px-4 sm:px-0">
                        <p class="text-indigo-600 text-6xl mb-4">01</p>
                        <h2 class="text-base font-semibold leading-7 text-gray-900">{{ steps[0].title }}</h2>
                        <p class="mt-1 text-md leading-6 text-gray-600">{{ steps[0].description }}</p>
                    </div>

                    <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div class="px-4 py-6 sm:p-8">
                            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div class="col-span-full">
                                    <label for="name" class="block text-md font-medium leading-6 text-gray-900">Nom<sup
                                            class="text-red-500 font-semibold text-base">*</sup></label>
                                    <div class="mt-2">
                                        <input type="text" name="name" id="name" autocomplete="name" required="required"
                                            v-model="formStructure.name"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                    </div>
                                </div>

                                <div class="col-span-full">
                                    <label for="laddress"
                                        class="block text-md font-medium leading-6 text-gray-900">Adresse</label>
                                    <div class="mt-2">
                                        <input type="text" name="address" id="address" autocomplete="address"
                                            v-model="formStructure.address"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                    </div>
                                </div>
                                <div class="sm:col-span-3">
                                    <label for="postal-code"
                                        class="block text-md font-medium leading-6 text-gray-900">Code postal<sup
                                            class="text-red-500 font-semibold text-base">*</sup>
                                    </label>
                                    <div class="mt-2">
                                        <input id="zip_code" name="zip_code" type="text"
                                            v-model="formStructure.zip_code" autocomplete="zip_code"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                    </div>
                                </div>
                                <div class="sm:col-span-3">
                                    <label for="city" class="block text-md font-medium leading-6 text-gray-900">Ville
                                    </label>
                                    <div class="mt-2">
                                        <input id="city" name="city" type="text" autocomplete="city"
                                            v-model="formStructure.city"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                    </div>
                                </div>
                                <div class="col-span-full flex items-center">
                                    <div class="w-3/5  mr-2">
                                        <Combobox as="div" class="w-full" v-model="formStructure.organization" required
                                            :key="organizationKey" @update:modelValue="query = ''">
                                            <ComboboxLabel class="block text-md font-medium leading-6 text-gray-900">
                                                Organisation
                                                associée<sup class="text-red-500 font-semibold text-base">*</sup>
                                            </ComboboxLabel>
                                            <div class="relative mt-2">
                                                <ComboboxInput
                                                    class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    @change="query = $event.target.value" @blur="query = ''"
                                                    :display-value="(organization) => organization?.name" />
                                                <ComboboxButton
                                                    class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400"
                                                        aria-hidden="true" />
                                                </ComboboxButton>

                                                <ComboboxOptions v-if="filteredOrganizations.length > 0"
                                                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    <ComboboxOption v-for="organization in filteredOrganizations"
                                                        :key="organization.id" :value="organization" as="template"
                                                        v-slot="{ active, selected }">
                                                        <li
                                                            :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                                            <span
                                                                :class="['block truncate', selected && 'font-semibold']">
                                                                {{ organization.name }}
                                                            </span>

                                                            <span v-if="selected"
                                                                :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        </li>
                                                    </ComboboxOption>
                                                </ComboboxOptions>
                                            </div>
                                        </Combobox>
                                    </div>
                                    <div class="mt-7">
                                        <AddOrganizationMini v-model="formStructure.organization"
                                            @update="updateOrganization" />
                                    </div>
                                </div>
                                <div class="col-span-full">
                                    <Listbox as="div" v-model="formStructure.legal_form">
                                        <ListboxLabel class="block text-md font-medium leading-6 text-gray-900">Statut
                                            de la structure<sup class="text-red-500 font-semibold text-base">*</sup>
                                        </ListboxLabel>
                                        <div class="relative mt-2">
                                            <ListboxButton
                                                class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-md sm:leading-6">
                                                <span class="block truncate" v-if="formStructure.legal_form.id">{{
            formStructure.legal_form.name
        }}</span><span v-else>Choisissez un statut</span>
                                                <span
                                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400"
                                                        aria-hidden="true" />
                                                </span>
                                            </ListboxButton>

                                            <transition leave-active-class="transition ease-in duration-100"
                                                leave-from-class="opacity-100" leave-to-class="opacity-0">
                                                <ListboxOptions
                                                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-md">
                                                    <ListboxOption as="template"
                                                        v-for="availableLegalForm in availableLegalForms"
                                                        :key="availableLegalForm.id" :value="availableLegalForm"
                                                        v-slot="{ active, selected }">
                                                        <li
                                                            :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                            <span
                                                                :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
            availableLegalForm.name }}</span>
                                                            <span v-if="selected"
                                                                :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        </li>
                                                    </ListboxOption>
                                                </ListboxOptions>
                                            </transition>
                                        </div>
                                    </Listbox>
                                </div>
                                <div class="col-span-full">
                                    <Combobox as="div" v-model="formStructure.types" @update:modelValue="query = ''"
                                        multiple>
                                        <ComboboxLabel class="block text-sm font-medium leading-6 text-gray-900">
                                            Type de structure
                                        </ComboboxLabel>
                                        <div class="my-3">
                                            <button v-for="typeStructure in formStructure.types" :key="typeStructure.id"
                                                type="button"
                                                class="mb-1 mr-2 inline-flex items-center gap-x-1.5 rounded-full bg-indigo-100 px-2.5 py-1.5 text-sm font-normal text-indigo-900 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                                {{ typeStructure.name }}
                                                <XCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true"
                                                    @click="removeTypeStructure(typeStructure.id)" />
                                            </button>
                                        </div>
                                        <div class="relative mt-2">
                                            <ComboboxInput
                                                class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                @change="query = $event.target.value" @blur="query = ''"
                                                :display-value="(typeStructure) => typeStructure?.name"
                                                placeholder="Choisissez un ou plusieurs types de structure" />
                                            <ComboboxButton
                                                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </ComboboxButton>

                                            <ComboboxOptions v-if="filteredTypes.length > 0"
                                                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                <ComboboxOption v-for="typeStructure in filteredTypes"
                                                    :key="typeStructure.id" :value="typeStructure" as="template"
                                                    v-slot="{ active, selected }">
                                                    <li
                                                        :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                                        <span :class="['block truncate', selected && 'font-semibold']">
                                                            {{ typeStructure.name }}
                                                        </span>

                                                        <span v-if="selected"
                                                            :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    </li>
                                                </ComboboxOption>
                                            </ComboboxOptions>
                                        </div>
                                    </Combobox>
                                </div>

                                <div class="col-span-full">
                                    <Combobox as="div" v-model="formStructure.publics" @update:modelValue="query = ''"
                                        multiple>
                                        <ComboboxLabel class="block text-sm font-medium leading-6 text-gray-900">
                                            Type de publics
                                        </ComboboxLabel>
                                        <div class="my-3">
                                            <button v-for="publicStructure in formStructure.publics"
                                                :key="publicStructure.id" type="button"
                                                class="mb-1 mr-2 inline-flex items-center gap-x-1.5 rounded-full bg-lime-100 px-2.5 py-1.5 text-sm font-normal text-indigo-900 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                                {{ publicStructure.name }}
                                                <XCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true"
                                                    @click="removePublicStructure(publicStructure.id)" />
                                            </button>
                                        </div>
                                        <div class="relative mt-2">
                                            <ComboboxInput
                                                class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                @change="query = $event.target.value" @blur="query = ''"
                                                :display-value="(publicStructure) => publicStructure?.name"
                                                placeholder="Choisissez un ou plusieurs types de publics" />
                                            <ComboboxButton
                                                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </ComboboxButton>

                                            <ComboboxOptions v-if="filteredPublics.length > 0"
                                                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                <ComboboxOption v-for="publicStructure in filteredPublics"
                                                    @change="selectPublicStructure(publicStructure)"
                                                    :key="publicStructure.id" :value="publicStructure" as="template"
                                                    v-slot="{ active, selected }">
                                                    <li
                                                        :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                                        <span :class="['block truncate', selected && 'font-semibold']">
                                                            {{ publicStructure.name }}
                                                        </span>

                                                        <span v-if="selected"
                                                            :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    </li>
                                                </ComboboxOption>
                                            </ComboboxOptions>
                                        </div>
                                    </Combobox>
                                </div>

                                <div class="col-span-full">
                                    <Combobox as="div" v-model="formStructure.degrees" @update:modelValue="query = ''"
                                        multiple>
                                        <ComboboxLabel class="block text-sm font-medium leading-6 text-gray-900">
                                            Formations acceptées
                                        </ComboboxLabel>
                                        <div class="my-3">
                                            <button v-for="degreeStructure in formStructure.degrees"
                                                :key="degreeStructure.id" type="button"
                                                class="mb-1 mr-2 inline-flex items-center gap-x-1.5 rounded-full bg-cyan-100 px-2.5 py-1.5 text-sm font-normal text-indigo-900 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                                {{ degreeStructure.name }}
                                                <XCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true"
                                                    @click="removeDegreeStructure(degreeStructure.id)" />
                                            </button>
                                        </div>
                                        <div class="relative mt-2">
                                            <ComboboxInput
                                                class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                @change="query = $event.target.value" @blur="query = ''"
                                                :display-value="(degreeStructure) => degreeStructure?.name"
                                                placeholder="Choisissez un ou plusieurs dilpômes" />
                                            <ComboboxButton
                                                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </ComboboxButton>

                                            <ComboboxOptions v-if="filteredDegrees.length > 0"
                                                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                <ComboboxOption v-for="degreeStructure in filteredDegrees"
                                                    :key="degreeStructure.id" :value="degreeStructure" as="template"
                                                    v-slot="{ active, selected }">
                                                    <li
                                                        :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                                        <span :class="['block truncate', selected && 'font-semibold']">
                                                            {{ degreeStructure.name }}
                                                        </span>

                                                        <span v-if="selected"
                                                            :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    </li>
                                                </ComboboxOption>
                                            </ComboboxOptions>
                                        </div>
                                    </Combobox>
                                </div>

                            </div>
                            <div class="col-span-full">
                                <AlertMessage v-if="errorMessage" type="error" :content="errorMessage" />
                            </div>
                        </div>

                        <div
                            class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button type="button" class="text-md font-semibold leading-6 text-gray-900">
                                Annuler
                            </button>
                            <button v-if="context === 'structureCreation'" class="rounded-md bg-indigo-600 px-3 py-2 text-md font-semibold text-white shadow-sm
                                hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                @click.prevent="createStructure">
                                Créer
                            </button>
                            <button v-else class="rounded-md bg-indigo-600 px-3 py-2 text-md font-semibold text-white shadow-sm
                                hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                @click.prevent="modifyStructure('inprogress')">
                                Enregistrer
                            </button>
                        </div>
                    </form>
                </div>

                <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3" id="presentation">
                    <div class="px-4 sm:px-0">
                        <p class="text-indigo-600 text-6xl mb-4">02</p>
                        <h2 class="text-base font-semibold leading-7 text-gray-900">{{ steps[1].title }}</h2>
                        <p class="mt-1 text-md leading-6 text-gray-600">{{ steps[1].description }}</p>
                    </div>

                    <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div class="px-4 py-6 sm:p-8">
                            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                <div class="col-span-full">
                                    <label for="street-address"
                                        class="block text-md font-medium leading-6 text-gray-900">Présentation</label>
                                    <div class="mt-2">
                                        <textarea rows="4" name="description" id="description"
                                            v-model="formStructure.description"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                    </div>
                                </div>

                                <div class="col-span-full">
                                    <label for="street-address"
                                        class="block text-md font-medium leading-6 text-gray-900">Ce qui
                                        m’intéresse
                                        dans le fait d’être une structure apprenante</label>
                                    <div class="mt-2">
                                        <textarea rows="4" name="structure_approach" id="structure_approach"
                                            v-model="formStructure.structure_approach"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                    </div>
                                </div>
                                <div class="col-span-full">
                                    <label for="street-address"
                                        class="block text-md font-medium leading-6 text-gray-900">Projet du
                                        moment,
                                        actualité de la structure</label>
                                    <div class="mt-2">
                                        <textarea rows="4" name="current_project" id="current_project"
                                            v-model="formStructure.current_project"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                    </div>
                                </div>
                                <div class="col-span-full">
                                    <label for="street-address"
                                        class="block text-md font-medium leading-6 text-gray-900">Si vous
                                        venez chez
                                        moi, il faut que vous sachiez que… </label>
                                    <div class="mt-2">
                                        <textarea rows="10" name="enrolment_speech" id="enrolment_speech"
                                            v-model="formStructure.enrolment_speech"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div
                            class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button type="button" class="text-md font-semibold leading-6 text-gray-900">
                                Annuler
                            </button>
                            <button v-if="context === 'structureCreation'" class="rounded-md bg-indigo-600 px-3 py-2 text-md font-semibold text-white shadow-sm
                                hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                @click.prevent="createStructure">
                                Créer
                            </button>
                            <button v-else class="rounded-md bg-indigo-600 px-3 py-2 text-md font-semibold text-white shadow-sm
                                hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                @click.prevent="modifyStructure('inprogress')">
                                Enregistrer
                            </button>
                        </div>
                    </form>
                    <div v-if="errorMessage" class="mt-4 text-red-500 text-center">
                        {{ errorMessage }}
                    </div>
                </div>
                <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3" id="situation"
                    :class="!structureId ? 'opacity-50 ' : ''">
                    <div class=" px-4 sm:px-0">
                        <p class="text-indigo-600 text-6xl mb-4">03</p>
                        <h2 class="text-base font-semibold leading-7 text-gray-900">{{ steps[2].title }}</h2>
                        <p class="mt-1 text-md leading-6 text-gray-600">{{ steps[2].description }}</p>
                    </div>
                    <div class="md:col-span-2">
                        <div class="card" v-if="formStructure.training_situations">
                            <!-- eslint-disable-next-line-->
                            <div v-for="trainingSituation in formStructure.training_situations"
                                :key:="trainingSituation.name">

                                <SituationCardAdmin :trainingSituationItem="trainingSituation"
                                    :attachedStructure="structureId" @update="fetchStructureDetails(structureId)" />
                            </div>

                            <button @click.prevent="createNewSituation = !createNewSituation" v-if="!createNewSituation"
                                type="button"
                                class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <NewspaperIcon class="w-12 h-12 stroke-1 stroke-gray-300 mx-auto mb-5" />
                                <span class="mt-2 block text-md font-medium text-gray-900">Créer une nouvelle
                                    situation de travail</span>
                            </button>
                            <SituationCardAdmin :trainingSituationItem="null" :editMode="'edition'" v-else
                                @update="fetchStructureDetails(structureId); createNewSituation = !createNewSituation" />
                        </div>
                        <div v-else>
                            <SituationCardAdmin :trainingSituationItem="null" :editMode="'edition'"
                                @update="fetchStructureDetails(structureId)" />
                        </div>
                    </div>
                </div>

                <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3" id="photo"
                    :class="!structureId ? 'opacity-50 ' : ''">
                    <div class="px-4 sm:px-0">
                        <p class="text-indigo-600 text-6xl mb-4">04</p>
                        <h2 class="text-base font-semibold leading-7 text-gray-900">{{ steps[3].title }}</h2>
                        <p class="mt-1 text-md leading-6 text-gray-600">{{ steps[3].description }}</p>
                    </div>
                    <AddStructurePhotos :structurePhotos="formStructure.photos"
                        @update="fetchStructureDetails(structureId)" />
                </div>
                <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3" id="situation"
                    v-if="structureId && currentProfile.role.name === 'Administrateur·trice'"
                    :class="!structureId ? 'opacity-50 ' : ''">
                    <div class=" px-4 sm:px-0">
                        <p class="text-indigo-600 text-6xl mb-4">05</p>
                        <h2 class="text-base font-semibold leading-7 text-gray-900">Profils associés</h2>
                        <p class="mt-1 text-md leading-6 text-gray-600">En tant qu'administrateur•rice vous pouvez gérer
                            les profils associés</p>
                    </div>
                    <div class="md:col-span-2">
                        <AddProfilesStructureRelated :idStructure="structureId" v-if="displayAddProfiles"
                            @confirm="fetchStructureDetails(structureId); displayAddProfiles = false"
                            @close="displayAddProfiles = false" />
                        <div v-for="( profiles ) in formStructure.attachedProfiles" :key="profiles">
                            <ProfilesRelatedToStructure :profiles="profiles" :idStructure="structureId"
                                @confirm="fetchStructureDetails(structureId)" />
                        </div>
                        <button @click="displayAddProfiles = true" class="btn-primary mt-5">Ajouter un profil</button>
                    </div>
                </div>
                <div class="w-full pt-5 flex" v-if="structureId">
                    <router-link :to="'/structures/' + structureId" class="ml-auto btn-read mr-2"
                        target="_blank"><button>
                            Voir la page publique
                        </button></router-link>
                    <button class="  rounded-md bg-emerald-400 px-3 py-2 text-md font-medium text-white shadow-sm
                                hover:bg-emerald-300 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        @click.prevent="modifyStructure('end')">
                        Terminer
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { XCircleIcon } from '@heroicons/vue/24/solid';
import { WrenchScrewdriverIcon, NewspaperIcon, UserIcon } from '@heroicons/vue/24/outline';
import {
    Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions
} from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import client from '@/api/client';
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/vue';
import AlertMessage from '@/components/alert/AlertMessage.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import SituationCardAdmin from '@/components/situations/SituationCardAdmin.vue';
import AddStructurePhotos from '@/components/structures/AddStructurePhotos.vue';
import fetchServices from '@/services/fetchServices.js';
import AddOrganizationMini from '@/components/admin/AddOrganizationMini.vue';
import ProfilesRelatedToStructure from '@/components/structures/ProfilesRelatedToStructure.vue';
import AddProfilesStructureRelated from '@/components/structures/AddProfilesStructureRelated.vue';

const route = useRoute();
const router = useRouter();

const props = defineProps({
    context: {
        String,
        default: 'default'
    },
});

const showNotification = ref(false);
const notificationType = ref('success');
const errorMessage = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');
const selectedPhoto = ref(null);
const displayAddProfiles = ref(false);

const steps = [
    { id: 'Step 1', name: 'Infos générales', href: '#infos', status: 'upcoming', title: 'Informations générales sur la structure', description: 'Renseignez ici les informations générales de localisation et contact sur la structure' },
    { id: 'Step 2', name: 'Présentation', href: '#presentation', status: 'upcoming', title: 'Présentation de la structure', description: 'Présentez ici la structure, ses activités, ses publics, ses spécificités, etc.' },
    { id: 'Step 3', name: 'Situation de travail', href: '#situation', status: 'upcoming', title: 'Situation de travail', description: 'Décrivez ici une situation de travail emblématique de votre structure' },
    { id: 'Step 4', name: 'Photos', href: '#photo', status: 'upcoming', title: 'Photos', description: 'Ajoutez ici des photos de la structure' },
]
const query = ref('');
const formStructure = ref({
    organization: [],
    types: [],
    legal_form: [],
});
const trainingSituation = ref([{
    name: '',
    description: '',
}]);

let structureId = ref(route.params.id);

const availableDegrees = ref([]);
const availableOrganizations = ref([]);
const availableLegalForms = ref([]);
const availablePublics = ref([]);
const availableTypes = ref([]);
const createNewSituation = ref(false);
const organizationKey = ref(0);

let selectedDegrees = ref([]);
let selectedPublics = ref([]);

const isFormComplete = computed(() => isFormFilled(formStructure.value));
// Function to check if form is filled
const isFormFilled = (formObject) => {

    for (const key in formObject) {
        if (typeof formObject[key] === 'object' && formObject[key] !== null) {
            // Check nested properties
            if (!isFormFilled(formObject[key])) {
                return false;
            }
        } else if (formObject[key] === null || formObject[key] === '') {
            return false;
        } else if (formObject[key].length === 0) {
            return false;
        }
    }
    if (selectedDegrees.value.length === 0) {
        return false;
    } else if (selectedPublics.value.length === 0) {
        return false;
    } else if (formStructure.value.organization.length === 0) {
        return false;
    } else if (formStructure.value.legal_form.length === 0) {
        return false;
    } else {
        return true;
    }
};
const store = inject('store');
const currentProfile = ref(store.chosenProfile);

const filteredOrganizations = computed(() =>
    query.value === ''
        ? availableOrganizations.value
        : availableOrganizations.value.filter((organization) => {
            return organization.name.toLowerCase().includes(query.value.toLowerCase())
        })
);
const filteredTypes = computed(() =>
    query.value === ''
        ? availableTypes.value
        : availableTypes.value.filter((type) => {
            return type.name.toLowerCase().includes(query.value.toLowerCase())
        })
);
const filteredPublics = computed(() =>
    query.value === ''
        ? availablePublics.value
        : availablePublics.value.filter((publicStructure) => {
            return publicStructure.name.toLowerCase().includes(query.value.toLowerCase())
        })
);
const filteredDegrees = computed(() =>
    query.value === ''
        ? availableDegrees.value
        : availableDegrees.value.filter((publicDegree) => {
            return publicDegree.name.toLowerCase().includes(query.value.toLowerCase())
        })
);

const removeTypeStructure = (typeStructure) => {
    const index = formStructure.value.types.findIndex((selectedType) => selectedType.id === typeStructure);
    formStructure.value.types.splice(index, 1);
};

const removePublicStructure = (publicStructure) => {
    const index = formStructure.value.publics.findIndex((selectedPublic) => selectedPublic.id === publicStructure);
    formStructure.value.publics.splice(index, 1);
};

const removeDegreeStructure = (degreeStructure) => {
    const index = formStructure.value.degrees.findIndex((selectedDegree) => selectedDegree.id === degreeStructure);
    formStructure.value.degrees.splice(index, 1);
};

const createStructure = async () => {
    if (Object.entries(formStructure.value.organization).length === 0 || Object.entries(formStructure.value.legal_form).length === 0) {
        errorMessage.value = 'Veuillez remplir les champs obligatoires.';
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        notificationMessage.value = 'Veuillez remplir les champs obligatoires. Vérifiez si l\'organisation et le statut ont bien été renseignés.';
        return;
    } else {
        try {
            const degreesToSave = formStructure.value.degrees?.map((degree) => degree.id);
            const publicsToSave = formStructure.value.publics?.map((publicStructure) => publicStructure.id);
            const typesToSave = formStructure.value.types?.map((typeStructure) => typeStructure.id);

            const response = await client.post('/api/structures', {
                name: formStructure.value.name,
                zip_code: formStructure.value.zip_code,
                organization_id: formStructure.value.organization.id,
                description: formStructure.value.description,
                structure_approach: formStructure.value.structure_approach,
                current_project: formStructure.value.current_project,
                enrolment_speech: formStructure.value.enrolment_speech,
                city: formStructure.value.city,
                address: formStructure.value.address,
                degrees: degreesToSave,
                publics: publicsToSave,
                types: typesToSave,
                legal_form_id: formStructure.value.legal_form.id,
            });

            if (response.status === 201) {
                showNotification.value = true;
                setTimeout(() => { showNotification.value = false; }, 3000);
                notificationType.value = 'success';
                notificationTitle.value = 'Bravo, la structure a bien été enregistrée';
                notificationMessage.value = 'Vous pouvez encore la modifier.';
                if (currentProfile.value.role.name === 'Formateur·trice métier') {
                    const responseProfile = await client.post(`/api/structures/${response.data.id}/add-profiles`, {
                        profile_ids: [currentProfile.value.id],
                    });
                }
                router.push({ name: 'Modifier une structure', params: { id: response.data.id } });
            }
        } catch (error) {
            console.log('error', error);
            errorMessage.value = 'Erreur lors de la création de la structure. ';
            notificationType.value = 'error';
            showNotification.value = true;
            notificationTitle.value = 'Erreur lors de l\'enregistrement';
            if (error.response) {
                errorMessage.value += error.response.data.message;
                notificationMessage.value = error.response.data.message;
            }
        }
    }

}

const modifyStructure = async (type) => {
    try {
        const degreesToSave = formStructure.value.degrees?.map((degree) => degree.id);
        const publicsToSave = formStructure.value.publics?.map((publicStructure) => publicStructure.id);
        const typesToSave = formStructure.value.types?.map((typeStructure) => typeStructure.id);

        const response = await client.put(`/api/structures/${structureId}`, {
            name: formStructure.value.name,
            zip_code: formStructure.value.zip_code,
            organization_id: formStructure.value.organization.id,
            description: formStructure.value.description,
            structure_approach: formStructure.value.structure_approach,
            current_project: formStructure.value.current_project,
            enrolment_speech: formStructure.value.enrolment_speech,
            city: formStructure.value.city,
            address: formStructure.value.address,
            degrees: degreesToSave,
            publics: publicsToSave,
            types: typesToSave,
            legal_form_id: formStructure.value.legal_form.id
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, la structure a bien été enregistrée';
        notificationMessage.value = 'Vous pourrez encore la modifier.';
        if (type === 'end') {
            router.push({ name: 'Tableau de bord', params: { id: formStructure.id } });
        }
        fetchStructureDetails(structureId);
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
};

const fetchStructureDetails = async (id) => {
    try {
        const response = await client.get(`/api/structures/${id}`);
        formStructure.value = response.data;
    } catch (error) {
    }
};
const updateOrganization = (organization) => {
    formStructure.value.organization = organization;
};
onMounted(() => {
    fetchServices.fetchDegreesPublics().then((data) => {
        availableDegrees.value = data;
    });
    fetchServices.fetchOrganizationsPublics().then((data) => {
        availableOrganizations.value = data;
    });
    fetchServices.fetchLegalFormsPublics().then((data) => {
        availableLegalForms.value = data;
    });
    fetchServices.fetchStructuresPublics().then((data) => {
        availablePublics.value = data;
    });
    fetchServices.fetchTypesPublics().then((data) => {
        availableTypes.value = data;
    });
    structureId = route.params.id;
    if (structureId) {
        fetchStructureDetails(structureId);
    }
});

</script>
