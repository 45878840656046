<template>
    <div class="">
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h2 class="text-base font-semibold leading-6 text-gray-900">Événements</h2>
                <p class="mt-2 text-sm text-gray-700">Liste des événements enregistrées</p>
            </div>
            <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button type="button" v-if="!cantEditItems"
                    @click="displayEditDialog = true; typeEdition = 'creation'; refreshModelForm();"
                    class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Créer
                    un événement</button>
            </div>
        </div>
        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Nom
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Actif
                                </th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span class="sr-only">Edition</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="relevantEvent in relevantEvents" :key="relevantEvent.id" class="even:bg-gray-50">
                                <td class="whitespace-nowrap px-3 py-4 text-sm font-medium">
                                    <p class="text-indigo-700"> {{
                    relevantEvent.name }}</p>
                                    <p class="text-black font-normal"> {{ fetchEventServices.formatDate(new
                    Date(relevantEvent.start_date)) }}</p>
                                    <p class="text-black font-normal"> {{ fetchEventServices.formatDate(new
                    Date(relevantEvent.end_date)) }}</p>
                                </td>

                                <td class=" whitespace-nowrap px-3 py-4 text-sm text-gray-500"> <span
                                        class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                                        v-if="relevantEvent.is_published === 1">actif</span><span v-else
                                        class="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">inactif</span>
                                </td>
                                <td>
                                    <div class="flex">
                                        <span class="badge-number">{{
                    relevantEvent.zones?.length }}</span>
                                        <MapPinIcon class="icon-event" aria-hidden="true"
                                            :class="[relevantEvent.zones?.length > 10 ? 'stroke-indigo-600' : 'stroke-gray-300']" />
                                        <span class="badge-number"> {{ relevantEvent.time_slots?.length }}</span>
                                        <CalendarDaysIcon class="icon-event" aria-hidden="true"
                                            :class="[relevantEvent.time_slots?.length > 5 ? 'stroke-indigo-600' : 'stroke-gray-300']" />

                                        <span class="badge-number"> {{ relevantEvent.participations?.length }}</span>
                                        <ClipboardDocumentCheckIcon class="icon-event" aria-hidden="true"
                                            :class="[relevantEvent.participations?.length > 50 ? 'stroke-indigo-600' : 'stroke-gray-300']" />

                                        <span class="badge-number"> {{ relevantEvent.wishlists?.length }}</span>
                                        <ListBulletIcon class="icon-event" aria-hidden="true"
                                            :class="[relevantEvent.wishlists?.length > 10 ? 'stroke-indigo-600' : 'stroke-gray-300']" />
                                    </div>
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium"
                                    v-if="!cantEditItems">
                                    <router-link :to="'evenements/edition/' + relevantEvent.id"> <button
                                            class="text-indigo-600 hover:text-indigo-900">Organiser
                                        </button></router-link>
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium"
                                    v-if="!cantEditItems">
                                    <button class="text-indigo-600 hover:text-indigo-900"
                                        @click="displayEditDialog = true; typeEdition = 'modification'; idTypeToEdit = relevantEvent.id; dataToModify = relevantEvent">Modifier
                                    </button>
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium"
                                    v-if="!cantEditItems">
                                    <button class="text-indigo-600 hover:text-indigo-900"
                                        @click="displayConfirmDeleteDialog = true; typeEdition = 'creation'; idTypeToEdit = relevantEvent.id;">Supprimer</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
                </div>
            </div>
        </div>
    </div>
    <AdminDialog @confirm="editItem" :typeEdition="typeEdition" :title="confirmTexts[1].title" :size="'fullscreen'"
        @close="displayEditDialog = false" v-model="typeModelForm" :objectToModify="dataToModify"
        v-if="displayEditDialog" />
    <ConfirmationDialog @confirm="deleteItem(idTypeToEdit)" v-if="displayConfirmDeleteDialog"
        :title="confirmTexts[0].title" :message="confirmTexts[0].message"
        :primaryActionLabel="confirmTexts[0].primaryActionLabel"
        :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed, inject } from 'vue';
import client from '@/api/client';
import Pagination from '@/components/lists/PaginationTables.vue';
import AdminDialog from '@/components/dialogs/AdminDialog.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import fetchEventServices from '@/services/EventServices.js';
import { CalendarDaysIcon, MapPinIcon, ClipboardDocumentCheckIcon, ListBulletIcon } from '@heroicons/vue/24/outline';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const cantEditItems = computed(() => (currentProfile.value.role.name === 'Formateur·trice référent·e'));

const relevantEvents = ref([]);

const displayEditDialog = ref(false);
const displayConfirmDeleteDialog = ref(false);
const displayZones = ref(false);
const typeEdition = ref('creation');
const idTypeToEdit = ref(null);
const dataToModify = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const errorMessage = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = ref(10);

const confirmTexts = [
    {
        title: 'Supprimer cet item ?',
        message: 'Voulez-vous supprimer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
    {
        title: 'Éditer un événement',
        message: 'Voulez-vous éditer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
]
const typeModelForm = ref([
    {
        field: 'name',
        label: 'Nom',
        type: 'text',
        entry: '',
    },
    {
        field: 'is_published',
        label: 'Actif',
        type: 'boolean',
        entry: true,
    },
    {
        field: 'description',
        label: 'Description',
        type: 'textarea',
        entry: '',
    },
    {
        field: 'address',
        label: 'Adresse',
        type: 'textarea',
        entry: '',
    },
    {
        field: 'zip_code',
        label: 'Code postal',
        type: 'text',
        entry: '',
    },
    {
        field: 'city',
        label: 'Ville',
        type: 'text',
        entry: '',
    },
    {
        field: 'start_date',
        label: 'Date de début',
        type: 'datetime-local',
        entry: '',
    },
    {
        field: 'end_date',
        label: 'Date de fin',
        type: 'datetime-local',
        entry: '',
    }
]);

const refreshModelForm = () => {
    typeModelForm.value.forEach((item) => {
        item.entry = '';
    });
};

const editItem = async (arrayToPost) => {
    const typeFormData = arrayToPost.value.reduce((formData, item) => {
        if (item.type === 'date') {
            formData[item.field] = new Date(item.entry);
        } else if (item.type === 'boolean') {
            if (item.entry) {
                formData[item.field] = 1;
            } else {
                formData[item.field] = 0;
            }
        } else
            formData[item.field] = item.entry;
        return formData;
    }, {});

    if (typeEdition.value === 'creation') {
        createItem(typeFormData);
    } else {
        modifyItem(typeFormData);
    }
};

const createItem = async (typeToCreate) => {
    displayEditDialog.value = false;
    try {
        const response = await client.post(`/api/events`, typeToCreate);
        const eventsUpdated = await fetchEventServices.fetchEvents(currentPage.value, pageSize.value);
        relevantEvents.value = eventsUpdated.data;
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        showNotification.value = true;
        notificationType.value = 'error';
        notificationTitle.value = 'Creation Error';
        notificationMessage.value = `Failed to create event ${error.response && error.response.data.message ? error.response.data.message : ''}`;
    }
};

const modifyItem = async (typeToModify) => {
    displayEditDialog.value = false;
    try {
        await client.put(`/api/events/${idTypeToEdit.value}`, typeToModify);
        const eventsUpdated = await fetchEventServices.fetchEvents(currentPage.value, pageSize.value);
        relevantEvents.value = eventsUpdated.data;
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre événement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    };
}

const deleteItem = async (typeToDelete) => {
    displayConfirmDeleteDialog.value = false;
    try {
        const response = await client.delete(`/api/events/${idTypeToEdit.value}`);
        const eventsUpdated = await fetchEventServices.fetchEvents(currentPage.value, pageSize.value);
        relevantEvents.value = eventsUpdated.data;
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre événement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
};

const changePage = (page) => {
    fetchEventServices.fetchEvents(page, pageSize.value).then((data) => {
        relevantEvents.value = data.data;
        currentPage.value = data.current_page;
    });
};

onMounted(async () => {
    try {
        const response = await fetchEventServices.fetchEvents(currentPage.value, pageSize.value);
        relevantEvents.value = response.data;
        console.log('Events fetched:', relevantEvents.value);
        currentPage.value = response.current_page;
        totalPages.value = response.last_page;
        totalItems.value = response.total;
    } catch (error) {
        console.error('Failed to fetch the next event:', error);
    }
});

</script>
