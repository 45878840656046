<template>
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-10" @click="$emit('close')">
            <div class="fixed inset-0" />
            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <TransitionChild as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md dialog-panel-structure">
                                <div class="flex h-full flex-col overflow-y-scrollpy-6 shadow-xl"
                                    :class="structureColor">
                                    <div class="px-4 sm:px-6 pt-10">
                                        <div class="flex items-start justify-between">
                                            <DialogTitle class="text-4xl font-light text-gray-900 mt-12">
                                                {{ chosenStructure?.name }}</DialogTitle>
                                            <div class="ml-3 flex h-7 items-center">
                                                <button type="button"
                                                    class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    @click="$emit('close')">
                                                    <span class="absolute -inset-2.5" />
                                                    <span class="sr-only">Close panel</span>
                                                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card relative mt-6 flex-1 px-4 sm:px-6">
                                        <div class="card-content">

                                            <div class="my-auto sm:mr-3">
                                                <div class="icon-line">
                                                    <MapPinIcon class="icon" aria-hidden="true" /><span
                                                        class="icon-text">
                                                        <p v-if="chosenStructure?.city">{{ chosenStructure?.city
                                                            }}</p>
                                                    </span>
                                                </div>
                                                <div class="icon-line">
                                                    <TagIcon class="icon" aria-hidden="true" /><span class="icon-text">
                                                        <p v-if="chosenStructure?.legal_form">{{
        chosenStructure.legal_form.name
    }}</p>
                                                    </span>
                                                </div>
                                                <div class="icon-line my-2">
                                                    <HomeIcon class="icon" aria-hidden="true" /><span class="-text">
                                                        <div class="flex flex-wrap leading-5">
                                                            <p v-for="(type, index) in chosenStructure?.types"
                                                                :key="type.id">{{ type.name }}<span
                                                                    v-if="index < (chosenStructure.types.length - 1)">,&nbsp;</span>
                                                            </p>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="icon-line ">
                                                    <UserIcon class="icon" aria-hidden="true" /><span class="icon-text">
                                                        <div class="flex flex-wrap leading-tight">
                                                            <p class=""
                                                                v-for="(publicType, index) in chosenStructure?.publics"
                                                                :key="publicType.id">
                                                                {{ publicType.name }}<span
                                                                    v-if="index < (chosenStructure.publics.length - 1)">,&nbsp;</span>
                                                            </p>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                        <section class="mt-10"
                                            v-for="( profiles, category ) in  chosenStructure.attachedProfiles "
                                            :key="profiles">
                                            <div v-for=" teacher  in  profiles " :key="teacher.id"
                                                class="flex items-center mb-4 md:mb-7">
                                                <router-link :to="'/utilisateurs/profil/' + teacher.id"
                                                    class="flex items-center mx-auto">
                                                    <div>
                                                        <div class="img-team-container !w-[90px] !h-[90px]">
                                                            <UserCircleIcon class="h-8 w-8 text-gray-300 m-auto"
                                                                aria-hidden="true" v-if="!teacher.profile_picture" />
                                                            <div v-else>
                                                                <img class="img-team !w-[90px] !h-[90px]"
                                                                    :src="teacher.profile_picture_url" alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ml-4">
                                                        <p class="text-md md:text-lg lg:text-xl">{{ teacher.name }}
                                                        </p>
                                                        <p
                                                            class="uppercase text-indigo-900 !tracking-widest text-sm md:textsm lg:text-md">
                                                            {{ category }}</p>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, inject } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { MapPinIcon, HomeIcon, TagIcon, UserIcon, UserCircleIcon } from '@heroicons/vue/24/outline';

const emit = defineEmits(['displayStructure', 'update', 'close']);
const open = ref(true);
defineProps({
    chosenStructure: Object,
    required: true,
});

const colors = ['bg-cyan-100', 'bg-blue-100', 'bg-green-100', 'bg-purple-100'];
const structureColor = computed(() => {
    return colors[Math.floor(Math.random() * colors.length)];
});
const store = inject('store');
const currentProfile = store.chosenProfile;

const isSelected = computed(() => {
    return store.selectedStructures.some(structure => structure.id === props.chosenStructure.id);
});

const toggleSelectStructure = (structure) => {
    if (isSelected.value) {
        store.removeStructure(structure.id);
    } else {
        store.addStructure(structure);
    }
};
</script>