<template>
    <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2" v-if="structureId">
        <div class="px-4 py-6 sm:p-8">
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                <div class="col-span-full flex flex-wrap gap-4">
                    <div v-for="photoStructure in structurePhotos" :key="photoStructure.id"
                        class="w-full sm:w-[48%] relative">
                        <img :src="photoStructure.url" alt="" class="w-full">
                        <div
                            class=" rounded-full bg-white h-10 w-10 absolute top-3 right-3 flex hover:bg-indigo-100 cursor-pointer">
                            <XMarkIcon class="h-7 w-7 m-auto" aria-hidden="true" v-if="photoStructure"
                                @click.prevent="removeStructurePhoto(photoStructure.id)" />
                        </div>
                    </div>
                    <div
                        class="w-full mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        <div class="text-center">
                            <PhotoIcon class="mx-auto w-12 h-12 stroke-1 stroke-gray-300 mx-auto mb-5"
                                aria-hidden="true" />
                            <div>
                            </div>

                            <div class="mt-4 flex text-sm leading-6 text-gray-600">
                                <label for="file-upload"
                                    class="relative cursor-pointer rounded-md bg-white font-normal text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                                    <input type="file" @change="selectStructureFile($event)" class="file-btn" />
                                </label>

                            </div>
                            <p class="text-normal leading-5 text-gray-600">jpeg, png, jpg, gif, svg,
                                maximum
                                2048 px</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { PhotoIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import client from '@/api/client';

const showNotification = ref(false);
const notificationType = ref('success');
const errorMessage = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');
const selectedPhoto = ref(null);
const route = useRoute();
let structureId = ref(route.params.id);

const emit = defineEmits(['update']);
const props = defineProps({
    structurePhotos: {
        Array,
        default: 'default'
    },
});

const selectStructureFile = (event) => {
    console.log('fiel', event.target.files[0]);
    const file = event.target.files[0];
    if (file) {

        selectedPhoto.value = file;
        console.log('selectedPhoto', selectedPhoto.value);
        uploadStructurePhoto();
    }
}

const uploadStructurePhoto = async () => {
    const formDataStructure = new FormData();
    formDataStructure.append('structure_photos[]', selectedPhoto.value);
    try {
        console.log('structureId', structureId.value);
        const response = await client.post(`/api/structure-photos/${structureId.value}`, formDataStructure, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        emit('update');
    } catch (error) {
        errorMessage.value = 'Erreur lors de la création du profil. ';
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
}
const removeStructurePhoto = async (photoId) => {
    console.log('photoId', photoId);
    try {
        const response = await client.delete(`/api/structure-photos/${structureId.value}/${photoId}`);
        emit('update');
    } catch (error) {
        errorMessage.value = 'Erreur lors de la création du profil. ';
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
}
</script>
