<template>
    <div class="mx-auto 2xl:max-w-7xl detail-page !px-0 lg:px-8">
        <div class="">
            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h2 class="text-xl text-indigo-700 font-semibold leading-6 text-gray-900">Zones</h2>
                    <p class="mt-2 text-sm text-gray-700">Liste des zones enregistrées</p>
                </div>
                <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <router-link to=""> <button type="button"
                            @click="displayEditDialog = true; typeEdition = 'creation'; refreshModelForm();"
                            class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Créer
                            une zone</button></router-link>
                </div>
            </div>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col"
                                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Nom
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Région
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Structures associées
                                    </th>
                                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span class="sr-only">Edition</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="relevantZone in relevantEvent.zones" :key="relevantZone.id"
                                    class="even:bg-gray-50">
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-indigo-700 font-medium">
                                        {{
                                relevantZone.name }}</td>
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-indigo-700 font-medium">
                                        {{
                                relevantZone.region }}</td>
                                    <td>
                                        <div v-for="structureRelated in relevantZone.structures"
                                            :key="structureRelated.id">{{ structureRelated.name }}</div>
                                    </td>
                                    <td>
                                        <span
                                            class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium">
                                            <button class="text-indigo-600 hover:text-indigo-900"
                                                @click="displayEditDialog = true; typeEdition = 'modification'; idTypeToEdit = relevantZone.id; dataToModify = relevantZone">Modifier
                                            </button></span>

                                        <span
                                            class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium">
                                            <button class="text-indigo-600 hover:text-indigo-900"
                                                @click="displayConfirmDeleteDialog = true; typeEdition = 'creation'; idTypeToEdit = relevantZone.id;">Supprimer</button>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <AdminDialog @confirm="editItem" :typeEdition="typeEdition" :title="confirmTexts[1].title"
        @close="displayEditDialog = false" v-model="typeModelForm" :objectToModify="dataToModify"
        v-if="displayEditDialog" :key="'zones'" />
    <ConfirmationDialog @confirm="deleteItem(idTypeToEdit)" v-if="displayConfirmDeleteDialog"
        :title="confirmTexts[0].title" :message="confirmTexts[0].message"
        :primaryActionLabel="confirmTexts[0].primaryActionLabel"
        :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />

</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import client from '@/api/client';
import AdminDialog from '@/components/dialogs/AdminDialog.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import fetchEventServices from '@/services/EventServices.js';
// import { CalendarDaysIcon } from '@heroicons/vue/20/solid';
import { CalendarDaysIcon, MapPinIcon, ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline';

const route = useRoute();
const displayEditDialog = ref(false);
const displayConfirmDeleteDialog = ref(false);
const typeEdition = ref('creation');
const idTypeToEdit = ref(null);
const dataToModify = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const errorMessage = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');

const relevantEvent = defineModel();
const emit = defineEmits(['confirm', 'close', 'update:modelValue']);

const confirmTexts = [
    {
        title: 'Supprimer cet item ?',
        message: 'Voulez-vous supprimer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
    {
        title: 'Éditer une zone',
        message: 'Voulez-vous éditer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
]
const typeModelForm = ref([
    {
        field: 'name',
        label: 'Nom',
        type: 'text',
        entry: '',
    },
    {
        field: 'region',
        label: 'Région',
        type: 'text',
        entry: '',
    },
]);

const refreshModelForm = () => {
    typeModelForm.value.forEach((item) => {
        item.entry = '';
    });
};

const editItem = async (arrayToPost) => {
    console.log('editItem');
    console.log(typeModelForm.value);
    const typeFormData = arrayToPost.value.reduce((formData, item) => {
        if (item.type === 'date') {
            formData[item.field] = new Date(item.entry);
        } else {
            formData[item.field] = item.entry;
        }
        return formData;
    }, { 'event_id': route.params.id });

    console.log(typeFormData);
    if (typeEdition.value === 'creation') {
        createItem(typeFormData);
    } else {
        modifyItem(typeFormData);
    }
};

const createItem = async (typeToCreate) => {
    console.log('createItem');
    displayEditDialog.value = false;
    try {
        const response = await client.post(`/api/zones`, typeToCreate);
        const responseEvent = await client.get(`api/events/${route.params.id}/related`);
        relevantEvent.value = responseEvent.data.event;
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre événement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
};

const modifyItem = async (typeToModify) => {
    console.log(typeToModify[0]);
    displayEditDialog.value = false;
    try {
        await client.put(`/api/zones/${idTypeToEdit.value}`, typeToModify);
        const responseEvent = await client.get(`api/events/${route.params.id}/related`);
        relevantEvent.value = responseEvent.data.event;
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre événement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    };
}

const deleteItem = async () => {
    console.log('deleteItem');
    displayConfirmDeleteDialog.value = false;
    try {
        const response = await client.delete(`/api/zones/${idTypeToEdit.value}`);
        const responseEvent = await client.get(`api/events/${route.params.id}/related`);
        relevantEvent.value = responseEvent.data.event;
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de votre événement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
};
onMounted(async () => {

});

</script>
