<template>
    <div class="card rounded-lg flex flex-col" :class="structureColor + ' ' + typeCard">
        <div class="card-header">
            <div class="flex items-center">
                <h1 class="title" v-if="typeCard === 'page-detail'">{{ displayedStructure.name }}</h1>
                <h2 class="title" v-else>{{ displayedStructure.name }}</h2>
                <StarIcon v-if="typeCard === 'list' && canSelect" class="h-8 w-8 ml-auto stroke-1 stroke-gray-400"
                    :class="isSelected ? 'selected' : ''" aria-hidden="true"
                    @click="toggleSelectStructure(displayedStructure)" />
            </div>
        </div>
        <div class="card-content sm:flex items-end" v-if="typeCard !== 'mini'">
            <div class="my-auto sm:mr-3">
                <div class="icon-line">
                    <MapPinIcon class="icon" aria-hidden="true" /><span class="icon-text">
                        <p v-if="displayedStructure.city">{{ displayedStructure?.city
                            }}</p>
                    </span>
                </div>
                <div class="icon-line">
                    <TagIcon class="icon" aria-hidden="true" /><span class="icon-text">
                        <p v-if="displayedStructure.legal_form">{{ displayedStructure.legal_form.name
                            }}</p>
                    </span>
                </div>
                <div class="icon-line my-2">
                    <HomeIcon class="icon" aria-hidden="true" /><span class="-text">
                        <div class="flex flex-wrap leading-5">
                            <p v-for="(type, index) in displayedStructure.types" :key="type.id">{{ type.name }}<span
                                    v-if="index < (displayedStructure.types.length - 1)">,&nbsp;</span></p>
                        </div>
                    </span>
                </div>
                <div class="icon-line ">
                    <UserIcon class="icon" aria-hidden="true" /><span class="icon-text">
                        <div class="flex flex-wrap leading-tight">
                            <p class="" v-for="(publicType, index) in displayedStructure.publics" :key="publicType.id">
                                {{ publicType.name }}<span
                                    v-if="index < (displayedStructure.publics.length - 1)">,&nbsp;</span></p>
                        </div>
                    </span>
                </div>
            </div>
            <div class="ml-auto mt-5 flex flex-wrap justify-center" v-if="typeCard == 'list' || typeCard == 'default'">
                <div class="more-icon cursor-pointer mt-2">
                    <MagnifyingGlassPlusIcon @click="$emit('displayStructure', displayedStructure.id)" />
                </div>
                <router-link class="more-icon mt-2" :to="'structures/' + displayedStructure.id">
                    <NewspaperIcon />
                </router-link>
            </div>
            <div class="ml-auto mt-3 flex sm:block" v-else-if="typeCard == 'admin'">
                <router-link :to="'/structures/modifier-ma-structure/' + displayedStructure.id">
                    <button class="btn-primary mb-2">Modifier
                        la structure</button> </router-link>
                <router-link :to="'structures/' + displayedStructure.id">
                    <button class="ml-2 btn-read">Voir la page publique</button>
                </router-link>
            </div>
        </div>
        <div v-if="typeCard === 'page-detail'" class="mt-5">
            <div v-if="currentProfile">
                <div @click="toggleSelectStructure(displayedStructure)"
                    v-if="currentProfile.role.name === 'Apprenant·e'"
                    @keyup.enter="toggleSelectStructure(displayedStructure)"
                    class="flex justify-center items-center mt-auto py-4 border-t border-gray-400">
                    <StarIcon class="h-8 w-8 stroke-1 stroke-gray-400" :class="isSelected ? 'selected' : ''"
                        aria-hidden="true" />
                    <span class="uppercase tracking-widest ml-2">sélectionner</span>
                </div>
            </div>
            <div v-else>
                <div @click="toggleSelectStructure(displayedStructure)"
                    @keyup.enter="toggleSelectStructure(displayedStructure)"
                    class="flex justify-center items-center mt-auto py-4 border-t border-gray-400">
                    <StarIcon class="h-8 w-8 stroke-1 stroke-gray-400" :class="isSelected ? 'selected' : ''"
                        aria-hidden="true" />
                    <span class="uppercase tracking-widest ml-2">sélectionner</span>
                </div>
            </div>
        </div>
    </div>
    <CreateAccountWarning v-if="displaySuccesDialog" title="Tu dois créer un compte pour enregistrer tes vœux"
        message="Ne perds pas ta sélection et inscris-toi maintenant !" primaryButtonLabel="Créer un compte"
        secondaryButtonLabel="" @closeDialog="displaySuccesDialog = false" />
</template>
<script setup>
/* eslint-disable */
import { ref } from 'vue';
import { computed, inject } from 'vue';
import { defineProps } from 'vue';
import { MapPinIcon, HomeIcon, TagIcon, UserIcon, StarIcon, MagnifyingGlassPlusIcon, NewspaperIcon } from '@heroicons/vue/24/outline';
import CreateAccountWarning from '@/components/dialogs/CreateAccountWarning.vue';

const props = defineProps({
    displayedStructure: {
        Object,
        required: true
    },
    typeCard: {
        String,
        default: 'default'
    },
});
const emit = defineEmits(['displayStructure', 'update']);
const colors = ['bg-cyan-100', 'bg-blue-100', 'bg-green-100', 'bg-purple-100'];
const open = ref(true);
const structureColor = computed(() => {
    return colors[Math.floor(Math.random() * colors.length)];
});
const store = inject('store');
const currentProfile = store.chosenProfile;
const canSelect = computed(() => {
    if (!currentProfile) {
        return true;
    } else if (currentProfile.role) {
        if (currentProfile.role.name === 'Apprenant·e') {
            return true;
        }
    } else {
        return false;
    }
});
const isSelected = computed(() => {
    return store.selectedStructures.some(structure => structure.id === props.displayedStructure.id);
});

const displaySuccesDialog = ref(false);
const toggleSelectStructure = (structure) => {
    if (isSelected.value) {
        store.removeStructure(structure.id);
        displaySuccesDialog.value = false;
    } else {
        store.addStructure(structure);
        if (!currentProfile) {
            displaySuccesDialog.value = true;
        }
    }
};
</script>

<style scoped>
.selected {
    fill: #2b1382;
}
</style>
